import { EnhancedTableHead, IHeadCell } from '@nayla/ui/components/TableHead';
import adminVocab from '@/vocabulary';
import useStyles from '@/components/InstalmentsTable/styles';
import * as React from 'react';
import { formatDate, getComparator, Order, stableSort } from '@nayla/common';
import { IOption } from '@stryberventures/gaia-react.combobox';
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableSection,
} from '@stryberventures/gaia-react.table';
import Text from '@/components/Text';
import { useNavigate } from 'react-router-dom';
import { IClientProfile } from '@/screens/client-profiles';

const headCells: readonly IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: adminVocab.clientProfilesTable.headCells.name,
  },
  {
    id: 'clientId',
    numeric: false,
    disablePadding: true,
    label: adminVocab.clientProfilesTable.headCells.id,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: adminVocab.clientProfilesTable.headCells.createdAt,
  },
];

export const ClientProfilesTable = ({ rows }: { rows: IClientProfile[] }) => {
  const classes = useStyles(),
    navigate = useNavigate(),
    [order, setOrder] = React.useState<Order>('asc'),
    [orderBy, setOrderBy] = React.useState<keyof IClientProfile>('id'),
    [selected] = React.useState<readonly string[]>([]),
    [page, setPage] = React.useState(0),
    [rowsPerPage, setRowsPerPage] = React.useState(10),
    handleRequestSort = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, property: keyof IClientProfile) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      },
      [order, orderBy],
    ),
    handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = React.useCallback((option: IOption) => {
      const updatedRowsPerPage = parseInt(option.label, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
    }, []),
    isSelected = (id: string) => selected.indexOf(id) !== -1,
    // Avoid a layout jump when reaching the last page with empty rows.
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <div className={classes.header}></div>
      <TableContainer>
        {!!selected.length && (
          <TableSection>
            <Text variant="components1">{selected.length} Items Selected</Text>
          </TableSection>
        )}

        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headcells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id),
                  labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={classes.tableRow}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    onClick={() => {
                      if (row.type === 'business') {
                        navigate(`/business-profile/${row.id}`, { state: row });
                      }

                      if (row.type === 'user') {
                        navigate(`/user-profile/${row.id}`, { state: row });
                      }
                    }}
                  >
                    <TableCell>{row.name ?? row.fallbackId}</TableCell>
                    <TableCell id={labelId}>{row?.clientId}</TableCell>
                    <TableCell>{formatDate(row.createdAt)}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
