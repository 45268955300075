import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import TextArea from '@stryberventures/gaia-react.text-area';
import Button from '@stryberventures/gaia-react.button';
import {
  formatDate,
  IUser,
  loanApplicationQueryKeys,
  queryClient,
  useCreateNoteForLoanApplicationMutation,
  useMeQuery,
} from '@nayla/common';
import { enqueueSnackbar } from 'notistack';
import Form from '@stryberventures/gaia-react.form';
import adminVocab from '@/vocabulary';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';
import { INote } from '@nayla/common/interfaces/note';
import dayjs from 'dayjs';

const HistoryTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loanApplication);
  const { mutate: createNote } = useCreateNoteForLoanApplicationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(data?.id, loanApplicationDetailViewExpandArray),
      );
      enqueueSnackbar('Note created', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Error creating note', { variant: 'error' });
    },
  });
  const currentUser = useMeQuery();
  const getUserName = (user: IUser | null) => {
    if (!user) return adminVocab.na;
    return user.firstName + ' ' + user.lastName;
  };

  return (
    <div className={classes.container}>
      <Form
        initialValues={{
          note: '',
        }}
        onSubmit={(values) => {
          createNote({
            id: data?.id,
            note: values.note,
          });
        }}
        className={classes.commentsForm}
      >
        <p style={{ fontWeight: 600 }}>Please add any comments here:</p>
        <div>
          <TextArea name="note" placeholder="Add comment here" />
          <p style={{ float: 'right' }}>{getUserName(currentUser.data)} (Credit Risk Officer)</p>
        </div>
        <div className={classes.formAction}>
          <span></span>
          <Button type="submit" className={classes.submit} variant="outlined">
            Insert comment
          </Button>
        </div>
      </Form>

      {data && data?.notes?.length > 0 && (
        <>
          <div className={classes.divider}></div>
          <div className={classes.notes}>
            <p style={{ fontWeight: 600 }}>Activity / Notes Log</p>
            {data?.notes
              ?.sort((a: INote, b: INote) => dayjs(b?.createdAt).valueOf() - dayjs(a?.createdAt).valueOf())
              .map((note) => {
                return (
                  <>
                    <p style={{ fontWeight: 600 }}>
                      {formatDate(note.createdAt)} - {getUserName(note.user) ?? 'System Message'}
                    </p>
                    <p>{note.text}</p>
                  </>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default HistoryTab;
