import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  tabs: {
    marginTop: toRem(5),
    active: {
      background: 'red',
    },
  },
  container: {
    marginTop: toRem(33),
    backgroundColor: theme.colors.contrast.white,
    borderRadius: toRem(12),
    padding: `${toRem(23)} ${toRem(90)} ${toRem(0)} ${toRem(40)}`,
    boxShadow: `0 ${toRem(4)} ${toRem(4)} ${toRem(0)} rgba(0, 0, 0, 0.10)`,
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    paddingTop: toRem(40),
    paddingBottom: toRem(40),
  },
});
