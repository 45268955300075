import { FC } from 'react';
import useStyles from '@/screens/client-profiles/BusinessProfiles/detail/tabs/aml/styles';
import { useAtomValue } from 'jotai';
import { userProfile } from '@/store/client-profile';
import adminVocab from '@/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import { AMLStatus, getTagColorByAMLState } from '@nayla/common';

const UserAMLTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(userProfile);

  const getFocalLink = (id: string) => {
    return `https://app.sa.focal.mozn.sa/records/customers/${id}/profile`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <span style={{ fontWeight: 600 }}>{adminVocab.loanApplication.aml.clientResults}:</span>
        <Tag
          style={{
            textDecoration: data?.amlStatus === AMLStatus.ACTION_REQUIRED ? 'underline' : '',
          }}
          onClick={() => {
            if (data?.amlStatus === AMLStatus.ACTION_REQUIRED && data?.id) {
              window.open(getFocalLink(data?.id), '_blank');
            }
          }}
          color={getTagColorByAMLState(data?.amlStatus)}
        >
          {adminVocab.loanApplication.amlStatuses[data?.amlStatus as AMLStatus] ??
            adminVocab.loanApplication.amlStatuses.NONE}
        </Tag>
      </div>
    </div>
  );
};

export default UserAMLTab;
