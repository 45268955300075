import { createUseStyles } from 'react-jss';

export default createUseStyles({
  closeIcon: {
    width: 16,
    height: 16,
    float: 'right',
    cursor: 'pointer',
  },
  dialog: {
    maxWidth: 'unset !important',
  },
});
