import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { addRole, deleteRole, getRole, getRoles, updateRole } from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import { IRole } from '../../interfaces';

export const useRolesQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['roles'], ({ signal }) => getRoles({ fetcher, options: { signal } }), {
    initialData: [],
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.roles.tableTitle), { variant: 'error' });
    },
  });
};

export const useRoleQuery = (id: string) => {
  const fetcher = useFetchWrapper();
  return useQuery(['roles', id], ({ signal }) => getRole(id, { fetcher, options: { signal } }), {
    initialData: null,
    enabled: !!id,
  });
};

export const useAddRoleMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((role: IRole) => addRole(role, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateRoleMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ id, role }: { id: string; role: IRole }) => updateRole(id, role, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDeleteRoleMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deleteRole(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
