import { useFetchWrapper } from '../../hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getSettings, updateSettings } from '../requests/settings';
import { IMutationOptions } from '../../interfaces/mutation';
import { ISettings } from '../../interfaces';

export const settingsQueryKeys = {
  settings: ['settings'],
};

export const useSettingsQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(settingsQueryKeys.settings, ({ signal }) => getSettings({ fetcher, options: { signal } }), {
    initialData: null,
    onError: () => {
      //enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanApplication.title), { variant: 'error' });
    },
  });
};

export const useUpdateSettingsMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((settings: Partial<ISettings>) => updateSettings(settings, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
