import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: toRem(25),
    alignItems: 'baseline',
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
    justifyContent: 'start',
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratesTable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: toRem(30),
    gap: toRem(15),
  },
  ratesRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: toRem(15),
  },
});
