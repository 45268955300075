import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: toRem(50),
  },
  paymentInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: toRem(15),
    alignItems: 'center',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(5),
  },
});
