import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  columns: {
    display: 'flex',
    gap: toRem(13),
  },
});
