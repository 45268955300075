import adminVocab from '@/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import { FC, useEffect } from 'react';
import useStyles from '@/components/Login/styles';
import useSignInStyles from '../SignIn/styles';
import Button from '@stryberventures/gaia-react.button';
import InputPassword from '@stryberventures/gaia-react.input-password';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Text from '@/components/Text';
import { enqueueSnackbar } from 'notistack';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  authState,
  ILoginAttempt,
  newPasswordChallengeState,
  passwordRegExp,
  useNewPasswordChallengeMutation,
} from '@nayla/common';

const NewPasswordChallenge: FC = () => {
  const classes = useStyles(),
    signInClasses = useSignInStyles();
  const newPasswordChallengeInfo = useAtomValue(newPasswordChallengeState);
  const navigate = useNavigate();
  useEffect(() => {
    if (!newPasswordChallengeInfo.session || !newPasswordChallengeInfo.userId) {
      enqueueSnackbar(adminVocab.authentication.temporaryPasswordMustBeUsed, { variant: 'error' });
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setToken = useSetAtom(authState);
  const onSuccess = (response: ILoginAttempt) => {
    if (response.result === 'TOKENS') {
      enqueueSnackbar(adminVocab.authentication.passwordChanged, { variant: 'success' });
      setToken(response.data.accessToken);
      return;
    }
    enqueueSnackbar(adminVocab.errors.oops, { variant: 'error' });
    navigate('/');
  };
  const onError = () => {
    enqueueSnackbar(adminVocab.errors.oops, { variant: 'error' });
  };
  const { mutate: newPasswordChallengeMutation, isLoading: isNewPasswordChangingLoading } =
    useNewPasswordChallengeMutation({
      onSuccess,
      onError,
    });
  const handleSubmit = (values: any) => {
    newPasswordChallengeMutation({ ...newPasswordChallengeInfo, newPassword: values.password });
  };

  return (
    <div className={signInClasses.container}>
      <div className={classes.wrapper}></div>
      <div className={classes.logoWrapper}></div>
      <div className={classes.screen}>
        <div className={classes.formContainer}>
          <Text align="center" component="h3" variant="h3" className={classes.title}>
            {adminVocab.authentication.newPassword}
          </Text>
          <Form
            className={classes.form}
            onSubmit={(data: any) => handleSubmit(data)}
            validationSchema={yup.object({
              password: yup.string().required().matches(passwordRegExp, {
                message: adminVocab.authentication.validationMessage,
              }),
              repeatPassword: yup
                .string()
                .nullable()
                .oneOf([yup.ref('password'), null], adminVocab.authentication.passwordsDoNotMatch),
            })}
            key={'new-password-form'}
          >
            <InputPassword
              height={56}
              name="password"
              autoComplete="new-password"
              variant="floatingLabel"
              hint={adminVocab.authentication.passwordHint}
              fullWidth
              label={adminVocab.users.properties.password}
              placeholder={adminVocab.placeholders.password}
            />
            <InputPassword
              height={56}
              name="repeatPassword"
              autoComplete="new-password"
              variant="floatingLabel"
              fullWidth
              label={adminVocab.authentication.repeatPassword}
              placeholder={adminVocab.placeholders.password}
            />
            <div className={signInClasses.buttonContainer}>
              <Button className={classes.button} type="submit">
                {isNewPasswordChangingLoading ? adminVocab.loading : adminVocab.submit}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordChallenge;
