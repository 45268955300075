import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'start',
    flexDirection: 'row',
    gap: '1.25rem',
    marginTop: toRem(30),
  },
  commentsForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
  },
  divider: {
    height: '20rem',
    width: toRem(1),
    backgroundColor: theme.colors.neutralGray.light200,
  },
  formAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notes: {
    overflowY: 'auto',
    height: toRem(400),
    width: toRem(400),
  },
  submit: {
    fontSize: toRem(10),
    height: toRem(23),
    width: toRem(194),
  },
});
