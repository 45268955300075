import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { ModalDialog } from '@/components/Dialog';
import { useRef, useState } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import InputPassword from '@stryberventures/gaia-react.input-password';
import * as yup from 'yup';
import Multiselect from '@stryberventures/gaia-react.multiselect';
import CheckBox from '@stryberventures/gaia-react.checkbox';
import { enqueueSnackbar } from 'notistack';
import {
  IRole,
  IUser,
  passwordRegExp,
  phoneRegExp,
  queryClient,
  useAddUserMutation,
  useRolesQuery,
  useUpdateUserMutation,
  useUserQuery,
} from '@nayla/common';

export interface IUserFormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  selectedUsername: string;
}

export const UserForm = ({ open, setOpen, selectedUsername }: IUserFormProps) => {
  const formRef = useRef<IFormRef>(null),
    classes = useStyles(),
    [changePassword, setChangePassword] = useState(false),
    handleFormSubmit = () => {
      formRef.current?.submit();
      if (formRef?.current?.isValid) {
        setOpen(false);
        return;
      }
    },
    { data: rolesData } = useRolesQuery(),
    { isLoading, data: userData } = useUserQuery(selectedUsername),
    { mutate: updateUser } = useUpdateUserMutation({
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.updated(adminVocab.users.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.update(adminVocab.users.entityName), { variant: 'error' });
      },
    }),
    { mutate: addUser } = useAddUserMutation({
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.created(adminVocab.users.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.create(adminVocab.users.entityName), { variant: 'error' });
      },
    });

  if (isLoading) {
    return null;
  }

  return (
    <ModalDialog
      setOpen={setOpen}
      open={open}
      title={selectedUsername ? adminVocab.users.editUserTitle : adminVocab.users.createUserTitle}
      onSubmit={handleFormSubmit}
    >
      <Form
        initialValues={{
          email: selectedUsername ? userData?.email : '',
          username: selectedUsername ? userData?.username : '',
          firstName: selectedUsername ? userData?.firstName : '',
          lastName: selectedUsername ? userData?.lastName : '',
          phone: selectedUsername ? userData?.phone?.replace(/\s/g, '') : '',
          roles: selectedUsername ? userData?.roles : [],
        }}
        validationSchema={yup.object({
          email: yup.string().required().email(),
          username: yup.string().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          roles: selectedUsername ? yup.array() : yup.array().required().min(1),
          phone: selectedUsername
            ? yup.string().nullable().matches(phoneRegExp, {
                message: adminVocab.errors.invalidPhone,
                excludeEmptyString: true,
              })
            : yup.string().matches(phoneRegExp, adminVocab.errors.invalidPhone),
          ...(selectedUsername &&
            changePassword && {
              password: yup.string().required().matches(passwordRegExp, {
                message: adminVocab.authentication.validationMessage,
              }),
              repeatPassword: yup
                .string()
                .nullable()
                .oneOf([yup.ref('password'), null], adminVocab.authentication.passwordsDoNotMatch),
            }),
        })}
        ref={formRef}
        onSubmit={(user: IUser) => {
          const roles = [];
          if (user.roles) {
            for (const userRole of user.roles) {
              for (const role of rolesData) {
                if (role.rolename === userRole) {
                  roles.push(role.id);
                }
              }
            }
          }

          if (selectedUsername) {
            if (changePassword) {
              delete user?.repeatPassword;
            }
            updateUser({
              username: selectedUsername,
              user: {
                ...user,
                roles,
              },
            });
          } else {
            addUser({
              ...user,
              roles,
            });
          }
          setOpen(false);
        }}
      >
        <div className={classes.container}>
          <Input
            autoComplete="email"
            label={adminVocab.users.properties.email}
            name="email"
            placeholder={adminVocab.placeholders.email}
          />
          <Input
            autoComplete="username"
            label={adminVocab.users.properties.username}
            name="username"
            placeholder={adminVocab.placeholders.username}
          />
          <Input
            autoComplete="given-name"
            label={adminVocab.users.properties.firstName}
            name="firstName"
            placeholder={adminVocab.placeholders.firstName}
          />
          <Input
            autoComplete="family-name"
            label={adminVocab.users.properties.lastName}
            name="lastName"
            placeholder={adminVocab.placeholders.lastName}
          />
          <Input
            autoComplete="tel"
            label={adminVocab.users.properties.phone}
            name="phone"
            placeholder={adminVocab.placeholders.phone}
          />
          <Multiselect
            name="roles"
            disabled={!rolesData.length}
            label={adminVocab.users.properties.roles}
            options={rolesData.map((role: IRole) => ({
              label: role.rolename,
              value: role.id,
            }))}
            placeholder={adminVocab.placeholders.select(adminVocab.roles.entityName)}
          />
          {selectedUsername && (
            <>
              <CheckBox
                name="changePassword"
                label={adminVocab.users.changePassword}
                onChange={() => setChangePassword(!changePassword)}
              />
              {changePassword && (
                <>
                  <InputPassword
                    autoComplete="current-password"
                    label={adminVocab.users.properties.password}
                    name="password"
                    placeholder={adminVocab.placeholders.password}
                  />
                  <InputPassword
                    autoComplete="current-password"
                    label={adminVocab.authentication.repeatPassword}
                    name="repeatPassword"
                    placeholder={adminVocab.placeholders.password}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Form>
    </ModalDialog>
  );
};
