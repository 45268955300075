import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import adminVocab from '@/vocabulary';
import { FC, useState } from 'react';
import Tabs from '@stryberventures/gaia-react.tabs';
import useStyles from './styles';
import UserInformationTab from '@/screens/client-profiles/UserProfiles/detail/tabs/information';
import UserAMLTab from '@/screens/client-profiles/UserProfiles/detail/tabs/aml';

const TAB_IDS = {
  INFO: 'info',
  KYC: 'kyc',
  AML: 'aml',
  DOCUMENTS: 'documents',
  LOAN_HISTORY: 'loan-history',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.INFO,
    label: adminVocab.userProfiles.tabs.kyc,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.AML,
    label: adminVocab.userProfiles.tabs.aml,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.LOAN_HISTORY,
    label: adminVocab.userProfiles.tabs.loanHistory,
  },
];

const UserProfileTabs: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TAB_IDS.INFO);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>
        {activeTab === TAB_IDS.INFO && <UserInformationTab />}
        {activeTab === TAB_IDS.AML && <UserAMLTab />}
      </div>
    </div>
  );
};

export default UserProfileTabs;
