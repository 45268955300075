import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    gap: toRem(50),
  },
  creditScore: {
    display: 'flex',
    gap: toRem(25),
    flexDirection: 'column',
  },
  riskAssessmentState: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tagCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  mainFigure: {
    color: theme.colors.contrast.black,
    fontWeight: 500,
    fontSize: toRem(14),
    lineHeight: toRem(28),
    [MEDIA_QUERIES.laptop]: {
      fontSize: toRem(28),
    },
  },
  comments: {
    display: 'flex',
    gap: toRem(60),
    flexDirection: 'column',
  },
  commentsSpace: {
    overflowY: 'auto',
    height: toRem(200),
  },
});
