import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: toRem(20),
  },
  info: {
    marginTop: toRem(20),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: toRem(10),
  },
});
