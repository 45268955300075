import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: `${toRem(282)} max-content`,
    gridAutoRows: 'minmax(min-content, max-content)',
    gap: toRem(15),
    marginTop: toRem(80),
  },
});
