import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexDirection: 'row',
    gap: '1.25rem',
    marginTop: toRem(30),
  },
  companyInformation: {},
  disbursementStatus: {},
  action: {},
  divider: {
    display: 'flex',
    width: toRem(290),
    height: toRem(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutralGray.light200,
  },
  tagCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  submit: {
    width: 'fit-content',
    height: toRem(40),
  },
});
