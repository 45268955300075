import { ISettings, queryClient } from '@nayla/common';
import { useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from '@/components/LoanProductForm/styles';
import { enqueueSnackbar } from 'notistack';
import adminVocab from '@/vocabulary';
import * as yup from 'yup';
import { ModalDialog } from '@/components/Dialog';
import Form from '@stryberventures/gaia-react.form';
import { settingsQueryKeys, useUpdateSettingsMutation } from '@nayla/common/rest/queries/settings';
import NumberInput from '@stryberventures/gaia-react.number-input';
import Input from '@stryberventures/gaia-react.input';

export interface IAdminSettingsFormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  adminSettings: ISettings;
}

export const AdminSettingsForm = ({ open, setOpen, adminSettings }: IAdminSettingsFormProps) => {
  const formRef = useRef<IFormRef>(null),
    classes = useStyles(),
    handleFormSubmit = () => {
      formRef.current?.submit();
      if (formRef?.current?.isValid) {
        setOpen(false);
        return;
      }
    },
    { mutate: updateSettings } = useUpdateSettingsMutation({
      onSettled: () => {
        queryClient.invalidateQueries(settingsQueryKeys.settings);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.updated(adminVocab.adminSettings.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.update(adminVocab.adminSettings.entityName), { variant: 'error' });
      },
    });

  const initialValues = {
    obConnectionTimeframeMinutes: adminSettings?.obConnectionTimeframeMinutes,
    obConnectionRetryIntervalMinutes: adminSettings?.obConnectionRetryIntervalMinutes,
    expiryTimeframeMinutes: adminSettings?.expiryTimeframeMinutes,
    instalmentCheckIntervalMilliseconds: adminSettings?.instalmentCheckIntervalMilliseconds,
    contractTemplateCode: adminSettings?.contractTemplateCode,
    instalmentOverdueDays: adminSettings?.instalmentOverdueDays,
    instalmentDelayedDays: adminSettings?.instalmentDelayedDays,
    instalmentUnsettledDays: adminSettings?.instalmentUnsettledDays,
    temporaryRejectionDays: adminSettings?.temporaryRejectionDays,
    loanTier1DelayDays: adminSettings?.loanTier1DelayDays,
    loanTier2DelayDays: adminSettings?.loanTier2DelayDays,
    loanTier3DelayDays: adminSettings?.loanTier3DelayDays,
    loanDefaultedDays: adminSettings?.loanDefaultedDays,
  };
  const validationSchema = yup.object({
    obConnectionTimeframeMinutes: yup.number(),
    obConnectionRetryIntervalMinutes: yup.number(),
    expiryTimeframeMinutes: yup.number(),
    instalmentCheckIntervalMilliseconds: yup.number(),
    contractTemplateCode: yup.string(),
    instalmentOverdueDays: yup.number(),
    instalmentDelayedDays: yup.number(),
    instalmentUnsettledDays: yup.number(),
    temporaryRejectionDays: yup.number(),
    loanTier1DelayDays: yup.number(),
    loanTier2DelayDays: yup.number(),
    loanTier3DelayDays: yup.number(),
    loanDefaultedDays: yup.number(),
  });

  return (
    <ModalDialog
      setOpen={setOpen}
      open={open}
      title={adminVocab.adminSettings.updateSettings}
      onSubmit={handleFormSubmit}
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        ref={formRef}
        onSubmit={(values: Partial<ISettings>) => {
          updateSettings(values);
        }}
      >
        <div className={classes.container}>
          <div className={classes.formColumn}>
            <NumberInput
              label={adminVocab.adminSettings.properties.obConnectionTimeframeMinutes}
              name="obConnectionTimeframeMinutes"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.obConnectionRetryIntervalMinutes}
              name="obConnectionRetryIntervalMinutes"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.expiryTimeframeMinutes}
              name="expiryTimeframeMinutes"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.instalmentCheckIntervalMilliseconds}
              name="instalmentCheckIntervalMilliseconds"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.temporaryRejectionDays}
              name="temporaryRejectionDays"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.loanTier1DelayDays}
              name="loanTier1DelayDays"
              type="number"
            />
          </div>
          <div className={classes.formColumn}>
            <NumberInput
              label={adminVocab.adminSettings.properties.instalmentOverdueDays}
              name="instalmentOverdueDays"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.instalmentDelayedDays}
              name="instalmentDelayedDays"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.instalmentUnsettledDays}
              name="instalmentUnsettledDays"
              type="number"
            />
            <Input
              label={adminVocab.adminSettings.properties.contractTemplateCode}
              name="contractTemplateCode"
              type="text"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.loanDefaultedDays}
              name="loanDefaultedDays"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.loanTier2DelayDays}
              name="loanTier2DelayDays"
              type="number"
            />
            <NumberInput
              label={adminVocab.adminSettings.properties.loanTier3DelayDays}
              name="loanTier3DelayDays"
              type="number"
            />
          </div>
        </div>
      </Form>
    </ModalDialog>
  );
};
