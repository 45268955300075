import { FC, useEffect } from 'react';
import adminVocab from '@/vocabulary';
import { LoanApplicationBoard } from '@/components/LoanApplicationBoard';
import { LoanApplicationState, useLoanApplicationsQuery } from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';
import { ILoanApplicationsFilters, LoanApplicationsFilters } from './LoanApplicationsFilters';
import * as React from 'react';
import Chip from '@stryberventures/gaia-react.chip';
import { CloseCircleIcon } from '@stryberventures/gaia-react.icons';
import Button from '@stryberventures/gaia-react.button';
import FilterListIcon from '@mui/icons-material/FilterList';
import useStyles from './styles';

const LoanApplications: FC = () => {
  const [query, setQuery] = React.useState(''),
    { data } = useLoanApplicationsQuery(['business'], query, 30 * 1000, 'always'),
    classes = useStyles(),
    [filtersModalShown, setFiltersModalShown] = React.useState(false),
    [filters, setFilters] = React.useState<ILoanApplicationsFilters>({
      from: null,
      to: null,
      state: null,
      loanProductIds: '',
    });

  /* Filter out loan applications with the following states:
   * - LoanApplicationState.DEFERRED
   * - LoanApplicationState.EXPIRED
   * - LoanApplicationState.REJECTED
   * - LoanApplicationState.DISBURSE
   * According to the design, they should not be visible on Kanban
   */
  const loanApplications = data
    .filter((loanApp) => !!loanApp.state)
    .filter(
      (loanApp) =>
        [
          LoanApplicationState.DEFERRED,
          LoanApplicationState.EXPIRED,
          LoanApplicationState.REJECTED,
          LoanApplicationState.DISBURSE,
        ].indexOf(loanApp.state) === -1,
    );
  console.log(loanApplications);

  useEffect(() => {
    const q = new URLSearchParams();
    if (filters.from) {
      q.append('createdAtFrom', filters.from.format());
    }
    if (filters.to) {
      q.append('createdAtTo', filters.to.format());
    }
    if (filters.state) {
      q.append('states[]', filters.state);
    }
    if (filters.loanProductIds) {
      q.append('loanProductIds[]', filters.loanProductIds);
    }

    setQuery(q.toString());
  }, [filters]);

  return (
    <Layout title={adminVocab.loanApplication.title} subtitle={adminVocab.loanApplication.description}>
      <div className={classes.container}>
        <div className={classes.filterContainer}>
          {filters.from && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, from: null });
                  }}
                />
              }
            >
              {'From: ' + (filters.from as any)?.format()?.substring(0, 10)}
            </Chip>
          )}
          {filters.to && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, to: null });
                  }}
                />
              }
            >
              {'To: ' + (filters.to as any)?.format()?.substring(0, 10)}
            </Chip>
          )}
          {filters.state && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, state: null });
                  }}
                />
              }
            >
              {'State: ' + adminVocab.loanApplication.states[filters.state]}
            </Chip>
          )}
          {filters.loanProductIds && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, loanProductIds: '' });
                  }}
                />
              }
            >
              {'Loan Products: ' + filters.loanProductIds}
            </Chip>
          )}
          <Button
            className={classes.toolbarIcon}
            onClick={() => setFiltersModalShown(true)}
            iconLeft={<FilterListIcon />}
            type="button"
            shape="round"
            variant="outlined"
          >
            {adminVocab.table.actions.filters}
          </Button>
        </div>
        <LoanApplicationBoard data={loanApplications} />
      </div>
      {filtersModalShown && (
        <LoanApplicationsFilters
          filters={filters}
          setFilters={setFilters}
          title={adminVocab.loanApplication.filtersTitle}
          open={filtersModalShown}
          setOpen={setFiltersModalShown}
        />
      )}
    </Layout>
  );
};

export default LoanApplications;
