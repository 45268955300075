import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    gridColumn: '4 / 5',
    gridRow: '1 / 1',
    overflow: 'hidden',
    padding: toRem(30),
    borderRadius: toRem(10),
    background: theme.colors.background.white,
  },
  userData: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: toRem(12),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(14),
    },
  },
});
