import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

const useStyles = createUseStyles({
  menuContainer: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: 0,
    overflow: 'hidden',
  },
  menuItem: {
    display: 'flex',
    cursor: 'pointer',
    fontFamily: theme.font,
    fontSize: 14,
    lineHeight: '16px',
    width: '100%',
    height: toRem(56),
    color: theme.colors.contrast.black,
    alignItems: 'center',
    paddingLeft: toRem(20),
    '&:hover': {
      backgroundColor: theme.colors.neutralGray.light200,
    },
  },
});

export default useStyles;
