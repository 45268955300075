import { formatDate, getTagColorByContractState, IContract } from '@nayla/common';
import useStyles from './styles';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import dayjs from 'dayjs';
import Tag from '@stryberventures/gaia-react.tag';
import adminVocab from '@/vocabulary';

const headCells = [
  { id: 'date', label: adminVocab.loanApplication.contract.contractSentOn },
  { id: 'contract', label: adminVocab.loanApplication.contract.contractLink },
  { id: 'status', label: adminVocab.loanApplication.contract.state },
];

interface IContractsTableProps {
  contracts: IContract[];
}

export const ContractsTable = ({ contracts = [] }: IContractsTableProps) => {
  const classes = useStyles();

  if (!contracts.length) {
    return (
      <div className={classes.noContracts}>
        <p>{adminVocab.loanApplication.contract.noContractsReceived}</p>
      </div>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}>{headCell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {contracts
            .sort((a: IContract, b: IContract) => dayjs(a?.createdAt).valueOf() - dayjs(b?.createdAt).valueOf())
            .map((contract: IContract, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{formatDate(contract?.createdAt)}</TableCell>
                  <TableCell>
                    <a target="_blank" href={contract.contractLinkAdmin ?? '#'} rel="noreferrer">
                      {contract.contractName ?? `Untitled ${index}`}
                    </a>
                  </TableCell>
                  <TableCell>
                    {' '}
                    {contract.contractStatus ? (
                      <Tag color={getTagColorByContractState(contract.contractStatus)}>
                        {adminVocab.loanApplication.contract.states[contract.contractStatus]}
                      </Tag>
                    ) : (
                      adminVocab.na
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractsTable;
