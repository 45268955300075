import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { ModalDialog } from '@/components/Dialog';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import * as yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import {
  IRiskCategory,
  loanProductsQueryKeys,
  queryClient,
  RiskCategoryEnum,
  useAddLoanProductMutation,
  useLoanProductQuery,
  useUpdateLoanProductMutation,
} from '@nayla/common';
import TextArea from '@stryberventures/gaia-react.text-area';
import NumberInput from '@stryberventures/gaia-react.number-input';
import vocab from '@nayla/vocabulary';

const riskRates: RiskCategoryEnum[] = [
  RiskCategoryEnum.A_PLUS,
  RiskCategoryEnum.A,
  RiskCategoryEnum.A_MINUS,
  RiskCategoryEnum.B_PLUS,
  RiskCategoryEnum.B,
];

export interface ILoanProductFormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  id?: string;
}

export const LoanProductForm = ({ open, setOpen, id }: ILoanProductFormProps) => {
  const formRef = useRef<IFormRef>(null),
    [numCategories, setNumCategories] = useState(2),
    classes = useStyles(),
    handleFormSubmit = () => {
      formRef.current?.submit();
      if (formRef?.current?.isValid) {
        setOpen(false);
        return;
      }
    },
    { isLoading, data: loanProduct } = useLoanProductQuery(id, ['riskCategories']),
    { mutate: updateLoanProduct } = useUpdateLoanProductMutation({
      onSettled: () => {
        queryClient.invalidateQueries(loanProductsQueryKeys.loanProducts);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.updated(adminVocab.loanProducts.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.update(adminVocab.loanProducts.entityName), { variant: 'error' });
      },
    }),
    { mutate: addLoanProduct } = useAddLoanProductMutation({
      onSettled: () => {
        queryClient.invalidateQueries(loanProductsQueryKeys.loanProducts);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.created(adminVocab.loanProducts.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.create(adminVocab.loanProducts.entityName), { variant: 'error' });
      },
    });

  const initialValues = useMemo(
    () => ({
      ...{
        name: id ? loanProduct?.name : '',
        minLoanAmount: id ? loanProduct?.minLoanAmount : '',
        maxLoanAmount: id ? loanProduct?.maxLoanAmount : '',
        description: id ? loanProduct?.description : '',
        minTerms: id ? loanProduct?.minTerms : '',
        maxTerms: id ? loanProduct?.maxTerms : '',
        adminFees: id ? loanProduct?.adminFees : '',
      },
      ...loanProduct?.riskCategories?.reduce((acc, riskCategory: IRiskCategory) => {
        acc[`riskRates[${riskCategory.name}].minRate`] = riskCategory.value;
        return acc;
      }, {} as any),
    }),
    [loanProduct, id],
  );

  const validationSchema = useMemo(() => {
    return yup.object({
      name: yup.string().required(adminVocab.errors.required(adminVocab.loanProducts.properties.name)),
      minLoanAmount: yup
        .string()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.errors.required(adminVocab.loanProducts.properties.minLoanAmount))
        .test(
          'is-less-than-max',
          adminVocab.errors.minLessThanMaxAmount, // Custom error message
          function (value) {
            const maxLoanAmount = this.parent.maxLoanAmount;
            return !maxLoanAmount || parseFloat(value) < parseFloat(maxLoanAmount);
          },
        ),
      maxLoanAmount: yup
        .string()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.errors.required(adminVocab.loanProducts.properties.maxLoanAmount)),
      description: yup.string().required(adminVocab.errors.required(adminVocab.loanProducts.properties.description)),
      minTerms: yup
        .number()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.errors.required(adminVocab.loanProducts.properties.minTerms))
        .test(
          'is-less-than-max-term',
          adminVocab.errors.minLessThanMaxTerm, // Custom error message
          function (value) {
            const maxLoanTerm = this.parent.maxTerms;
            return !maxLoanTerm || value < maxLoanTerm;
          },
        ),
      maxTerms: yup
        .number()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.errors.required(adminVocab.loanProducts.properties.maxTerms)),
      adminFees: yup
        .string()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.errors.required(adminVocab.loanProducts.properties.adminFees)),
    });
  }, []);

  useEffect(() => {
    if (id && loanProduct?.riskCategories?.length) {
      setNumCategories(loanProduct?.riskCategories?.length);
    }
  }, [id, loanProduct]);

  if (isLoading) {
    return null;
  }

  return (
    <ModalDialog
      setOpen={setOpen}
      open={open}
      title={id ? adminVocab.loanProducts.editTitle : adminVocab.loanProducts.createTitle}
      onSubmit={handleFormSubmit}
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        ref={formRef}
        onSubmit={(lp: any) => {
          lp.riskCategories = [...riskRates].slice(0, numCategories).map((riskRate) => {
            const value = lp[`riskRates[${riskRate}].minRate`];
            delete lp[`riskRates[${riskRate}].minRate`];
            return {
              name: riskRate,
              value: value,
            };
          });
          lp.minTerms = Number(lp.minTerms);
          lp.maxTerms = Number(lp.maxTerms);
          if (id) {
            updateLoanProduct({
              id: id,
              loanProduct: lp,
            });
          } else {
            addLoanProduct(lp);
          }
          setOpen(false);
        }}
      >
        <div className={classes.container}>
          <div className={classes.formColumn}>
            <Input
              label={adminVocab.loanProducts.properties.name}
              name="name"
              placeholder={adminVocab.placeholders.loanProductName}
            />
            <Input
              label={`${adminVocab.loanProducts.properties.minLoanAmount} (SAR)`}
              type="text"
              name="minLoanAmount"
              placeholder={adminVocab.placeholders.minLoanAmount}
            />
            <Input
              label={`${adminVocab.loanProducts.properties.minTerms} (months)`}
              type="number"
              name="minTerms"
              placeholder={adminVocab.placeholders.minTerms}
            />
            <TextArea
              className={classes.textArea}
              label={adminVocab.loanProducts.properties.description}
              name="description"
              placeholder="Add comment here"
            />
          </div>
          <div className={classes.formColumn}>
            <Input
              label={`${adminVocab.loanProducts.properties.adminFees} (%)`}
              type="text"
              name="adminFees"
              placeholder={adminVocab.placeholders.adminFees}
            />
            <Input
              type="text"
              label={`${adminVocab.loanProducts.properties.maxLoanAmount} (SAR)`}
              name="maxLoanAmount"
              placeholder={adminVocab.placeholders.maxLoanAmount}
            />
            <Input
              type="number"
              label={`${adminVocab.loanProducts.properties.maxTerms} (months)`}
              name="maxTerms"
              placeholder={adminVocab.placeholders.maxTerms}
            />
          </div>
          <div className={classes.formColumn}>
            <NumberInput
              name="numCategories"
              label={adminVocab.loanProducts.properties.numCategories}
              quantityCounter
              onChange={(value) => setNumCategories(value)}
              value={numCategories}
              controlled
              min={2}
              max={5}
            ></NumberInput>
            {numCategories > 0 && (
              <div className={classes.ratesTable}>
                {[...riskRates].slice(0, numCategories).map((riskRate, index) => (
                  <div key={index} className={classes.ratesRow}>
                    <Input
                      name={`riskRates[${riskRate}].minRate`}
                      label={`${vocab().loanProducts.riskCategories?.[riskRate]} (monthly, %)`}
                      type="number"
                      placeholder={adminVocab.placeholders.maxLoanAmount}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Form>
    </ModalDialog>
  );
};
