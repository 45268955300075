import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  btn: {
    float: 'right',
    display: 'flex',
    alignSelf: 'end',
    color: theme.colors.primary.medium400,
    fontSize: toRem(14),
    fontWeight: 400,
    lineHeight: toRem(20),
    textDecorationLine: 'underline',
  },
});
