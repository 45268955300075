import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: toRem(24),
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '40px',
    color: theme.colors.text.headline,
  },
  description: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.text.secondary,
  },
  createItem: {
    width: 137,
    height: 40,
    marginLeft: toRem(16),
  },
  activeChip: {
    backgroundColor: theme.colors.success.extraLight50 + '!important',
    color: theme.colors.success.main500 + '!important',
  },
  failureChip: {
    backgroundColor: theme.colors.error.extraLight50 + '!important',
    color: theme.colors.error.main500 + '!important',
  },
  tableRow: {
    height: toRem(68),
    '&:hover > td:last-child > div > div[class*="actionsContainer"]': {
      display: 'inline-flex !important',
    },
    '& > td, & > th': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      boxSizing: 'border-box',
      height: toRem(68),
      maxWidth: toRem(200),
    },
    '& > td:last-child': {
      minWidth: toRem(250),
    },
    '& > td:last-child > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  actionsContainer: {
    display: 'none',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: toRem(25),
  },
  actionIcon: {
    width: toRem(32),
    height: toRem(32),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > svg': {
      width: toRem(18),
      height: toRem(18),
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.neutralGray.light100,
      borderRadius: toRem(16),
    },
  },
});
