import { useRef, useState } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import Form from '@stryberventures/gaia-react.form';
import adminVocab from '@/vocabulary';
import { DatePicker } from '@mui/x-date-pickers';
import { LoanApplicationState } from '@nayla/common';
import { IOption } from '@nayla/ui/components/Select';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { Dayjs } from 'dayjs';

export interface ILoanApplicationsFilters {
  from: Dayjs | null;
  to: Dayjs | null;
  state: LoanApplicationState | null;
  loanProductIds: string;
}

export interface ILoanApplicationsFiltersProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  title: string;
  filters: ILoanApplicationsFilters;
  setFilters: (filters: ILoanApplicationsFilters) => void;
}

const stateOptions: IOption[] = Object.entries(LoanApplicationState).map(([value]) => ({
  label: adminVocab.loanApplication.states[value as LoanApplicationState],
  value: value as LoanApplicationState,
}));

export const LoanApplicationsFilters = ({
  open,
  setOpen,
  title,
  filters,
  setFilters,
}: ILoanApplicationsFiltersProps) => {
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(filters.from);
  const [dateTo, setDateTo] = useState<Dayjs | null>(filters.to);
  const [state, setState] = useState(filters.state);
  const [loanProductIds] = useState<string>(filters.loanProductIds);
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles(),
    handleFormSubmit = () => {
      formRef.current?.submit();
      if (formRef?.current?.isValid) {
        setOpen(false);
        return;
      }
    };
  return (
    <ModalDialog setOpen={setOpen} open={open} title={title} onSubmit={handleFormSubmit} submitText="Apply">
      <Form
        ref={formRef}
        onSubmit={() => {
          setFilters({
            from: dateFrom,
            to: dateTo,
            state,
            loanProductIds,
          });
        }}
      >
        <div className={classes.container}>
          <div className={classes.filterCaption}>{adminVocab.loanApplication.filters.startDate}</div>
          <div className={classes.datePickers}>
            <div>
              <DatePicker
                label={adminVocab.loanApplication.filters.from}
                onChange={(date) => setDateFrom(date)}
                value={dateFrom}
              />
            </div>
            <div>
              <DatePicker
                label={adminVocab.loanApplication.filters.to}
                onChange={(date) => setDateTo(date)}
                value={dateTo}
              />
            </div>
          </div>
          <div className={classes.filterCaption}>{adminVocab.loanApplication.filters.status}</div>
          <Select
            label={adminVocab.loanApplication.properties.state}
            onChange={(event: SelectChangeEvent) => {
              setState(event.target.value as LoanApplicationState);
            }}
          >
            {stateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {/*TODO uncomment once works properly*/}
          {/*<Input fullWidth type='text'*/}
          {/*       label={adminVocab.loanApplication.properties.loanProductIds}*/}
          {/*       onChange={(e: React.BaseSyntheticEvent) => {*/}
          {/*  setLoanProductIds(e.target.value);*/}
          {/*}}/>*/}
        </div>
      </Form>
    </ModalDialog>
  );
};
