export default {
  apiUrl: 'http://localhost:3010',
  userMgmtUrl: 'http://localhost:3001/v1',
  authUrl: 'http://localhost:5555',
  cognitoGoogleSignIn: {
    domain: 'https://idcs-6cfe88010e8446f986676ef822c8500c.identity.oraclecloud.com',
    clientId: '322bf10a7c1944cfbd5a4c0b1902166f',
    responseType: 'code',
    redirectUri: 'http://localhost:8080/signin',
    state: 'state',
    scopes: ['openid', 'email', 'urn:opc:idm:t.user.me'],
    identityProvider: 'Google',
  },
};
