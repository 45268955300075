import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: toRem(50),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(20),
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btn: {
    height: toRem(40),
    width: toRem(200),
  },
  noContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: toRem(30),
  },
});
