import { IFetchRequest, ISettings } from '../../interfaces';
import { common } from '../index';

export const getSettings = ({ fetcher, options }: IFetchRequest): Promise<ISettings> => {
  return fetcher.get(`${common.endpoints.path.SETTINGS_ENDPOINT}`, options);
};

export const updateSettings = (
  settings: Partial<ISettings>,
  { fetcher, options }: IFetchRequest,
): Promise<ISettings> => {
  return fetcher.patch(common.endpoints.path.SETTINGS_ENDPOINT, { body: JSON.stringify(settings), ...options });
};
