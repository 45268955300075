import { IFetchRequest, IUser } from '../../interfaces';
import { common } from '../index';

export const getApiUser = (id: string | undefined, { fetcher, options }: IFetchRequest): Promise<IUser> => {
  if (!id) throw new Error('id is required');
  return fetcher.get(`${common.endpoints.path.API_USER_ENDPOINT}/${id}`, options);
};

export const getApiUsers = ({ fetcher, options }: IFetchRequest): Promise<IUser[]> => {
  return fetcher.get(common.endpoints.path.API_USER_ENDPOINT, options);
};

export const addApiUser = (user: IUser, { fetcher, options }: IFetchRequest): Promise<IUser> => {
  return fetcher.post(common.endpoints.path.API_USER_ENDPOINT, { body: JSON.stringify(user), ...options });
};

export const updateApiUser = (id: string, user: IUser, { fetcher, options }: IFetchRequest): Promise<IUser> => {
  return fetcher.patch(`${common.endpoints.path.API_USER_ENDPOINT}/${id}`, { body: JSON.stringify(user), ...options });
};

export const deleteApiUser = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.API_USER_ENDPOINT}/${id}`, options);
};
