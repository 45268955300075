import * as React from 'react';
import { useEffect } from 'react';
import Text from '@/components/Text';
import adminVocab from '@/vocabulary';
import useStyles from './styles';
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableSection,
} from '@stryberventures/gaia-react.table';
import { IOption } from '@stryberventures/gaia-react.combobox';
import {
  formatDate,
  getComparator,
  getTagColorByInstalmentState,
  IInstalment,
  InstalmentState,
  numberWithCommas,
  Order,
  stableSort,
  useInstalmentsQuery,
} from '@nayla/common';
import { EnhancedTableHead, IHeadCell } from '@nayla/ui/components/TableHead';
import Tag from '@stryberventures/gaia-react.tag';
import vocab from '@nayla/vocabulary';
import Button from '@stryberventures/gaia-react.button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { InstalmentsFilters } from '@/components/InstalmentsTable/InstalmentsFilters';
import Chip from '@stryberventures/gaia-react.chip';
import { CloseCircleIcon } from '@stryberventures/gaia-react.icons';
import { Dayjs } from 'dayjs';

export interface IInstalmentsFilters {
  from: Dayjs | null;
  to: Dayjs | null;
  state: InstalmentState | null;
}

const headCells: readonly IHeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: adminVocab.instalments.properties.id,
  },
  {
    id: 'loanId',
    numeric: false,
    disablePadding: false,
    label: adminVocab.instalments.properties.loanId,
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: adminVocab.instalments.properties.dueDate,
  },
  {
    id: 'receivedAmount',
    numeric: false,
    disablePadding: false,
    label: adminVocab.instalments.properties.receivedAmount,
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: adminVocab.instalments.properties.state,
  },
];

export const InstalmentsTable = () => {
  const classes = useStyles(),
    [query, setQuery] = React.useState(''),
    { data: rows } = useInstalmentsQuery(query),
    [order, setOrder] = React.useState<Order>('asc'),
    [orderBy, setOrderBy] = React.useState<keyof IInstalment>('id'),
    [selected] = React.useState<readonly string[]>([]),
    [page, setPage] = React.useState(0),
    [filtersModalShown, setFiltersModalShown] = React.useState(false),
    [filters, setFilters] = React.useState<IInstalmentsFilters>({
      from: null,
      to: null,
      state: null,
    }),
    [rowsPerPage, setRowsPerPage] = React.useState(10),
    handleRequestSort = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, property: keyof IInstalment) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      },
      [order, orderBy],
    ),
    handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = React.useCallback((option: IOption) => {
      const updatedRowsPerPage = parseInt(option.label, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
    }, []),
    isSelected = (id: string) => selected.indexOf(id) !== -1,
    // Avoid a layout jump when reaching the last page with empty rows.
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    const q = new URLSearchParams();
    if (filters.from) {
      q.append('dueDateFrom', filters.from.format());
    }
    if (filters.to) {
      q.append('dueDateTo', filters.to.format());
    }
    if (filters.state) {
      q.append('states[]', filters.state);
    }
    setQuery(q.toString());
  }, [filters]);

  return (
    <>
      <div className={classes.header}></div>
      <TableContainer>
        {!!selected.length && (
          <TableSection>
            <Text variant="components1">{selected.length} Items Selected</Text>
          </TableSection>
        )}
        <div className={classes.filterContainer}>
          {filters.from && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, from: null });
                  }}
                />
              }
            >
              {'From: ' + (filters.from as any)?.format()?.substring(0, 10)}
            </Chip>
          )}
          {filters.to && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, to: null });
                  }}
                />
              }
            >
              {'To: ' + (filters.to as any)?.format()?.substring(0, 10)}
            </Chip>
          )}
          {filters.state && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, state: null });
                  }}
                />
              }
            >
              {'State: ' + vocab().instalments.states[filters.state]}
            </Chip>
          )}
          <Button
            className={classes.toolbarIcon}
            onClick={() => setFiltersModalShown(true)}
            iconLeft={<FilterListIcon />}
            type="button"
            shape="round"
            variant="outlined"
          >
            {adminVocab.table.actions.filters}
          </Button>
        </div>

        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headcells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id),
                  labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={classes.tableRow}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell id={labelId}>{row.externalId}</TableCell>
                    <TableCell>{row?.loan?.externalId}</TableCell>
                    <TableCell>{formatDate(row.dueDate)}</TableCell>
                    <TableCell>{row?.receivedAmount ? numberWithCommas(row.receivedAmount) : null}</TableCell>
                    <TableCell>
                      {row.state ? (
                        <Tag color={getTagColorByInstalmentState(row.state)}>
                          {vocab().instalments.states[row.state]}
                        </Tag>
                      ) : (
                        adminVocab.na
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {filtersModalShown && (
        <InstalmentsFilters
          filters={filters}
          setFilters={setFilters}
          title={adminVocab.instalments.filtersTitle}
          open={filtersModalShown}
          setOpen={setFiltersModalShown}
        />
      )}
    </>
  );
};
