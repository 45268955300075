import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { useApiUserQuery } from '@nayla/common';
import { useSetAtom } from 'jotai';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { userProfile } from '@/store/client-profile';
import UserProfileOverview from '@/screens/client-profiles/UserProfiles/detail/overview';
import UserProfileContactInfo from '@/screens/client-profiles/UserProfiles/detail/contact';
import UserProfileTabs from '@/screens/client-profiles/UserProfiles/detail/tabs';

const UserClientProfileDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data } = useApiUserQuery(id);
  const setUserProfile = useSetAtom(userProfile);

  useEffect(() => {
    setUserProfile(data);
  }, [data, setUserProfile]);

  return (
    <Layout subtitle={data?.externalId} title={adminVocab.userProfiles.title}>
      {data && (
        <div className={classes.grid}>
          <UserProfileOverview />
          <UserProfileContactInfo />
          <UserProfileTabs />
        </div>
      )}
    </Layout>
  );
};

export default UserClientProfileDetail;
