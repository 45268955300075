import { useAtomValue } from 'jotai';
import { loan } from '@/store/loan';
import { FC } from 'react';
import { PaymentSchedule } from '@nayla/ui';

const InstalmentsStatusTab: FC = () => {
  const loanData = useAtomValue(loan);
  const amortizations: any[] | undefined = loanData?.calculation?.amortizationTable ?? [];

  return <PaymentSchedule amortizations={amortizations} />;
};

export default InstalmentsStatusTab;
