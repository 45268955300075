import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { addApiUser, deleteApiUser, getApiUser, getApiUsers, updateApiUser } from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import { IUser } from '../../interfaces';

export const apiUserQueryKeys = {
  users: () => ['api-users'],
  user: (id: string | undefined) => ['api-users', id],
};

export const useApiUsersQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(apiUserQueryKeys.users(), ({ signal }) => getApiUsers({ fetcher, options: { signal } }), {
    initialData: [],
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};

export const useApiUserQuery = (username?: string) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    apiUserQueryKeys.user(username),
    ({ signal }) => getApiUser(username, { fetcher, options: { signal } }),
    {
      initialData: null,
      enabled: !!username,
    },
  );
};

export const useAddApiUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((user: IUser) => addApiUser(user, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateApiUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ username, user }: { username: string; user: IUser }) => updateApiUser(username, user, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useDeleteApiUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((username: string) => deleteApiUser(username, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
