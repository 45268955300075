import { FC } from 'react';
import useStyles from '@/screens/LoanApplications/detail/contact/styles';
import Layout from '@nayla/ui/components/Layout';
import { CalculatorLoan } from '@nayla/ui';
import { ILoanProduct, useLoanProductsQuery } from '@nayla/common';

const AdminLoanCalculator: FC = () => {
  const classes = useStyles();
  const { data: loanProducts, isLoading: isLoanProductsLoading, isFetching } = useLoanProductsQuery();

  if (!loanProducts || loanProducts.length === 0) {
    return (
      <Layout title="Loan Calculator" subtitle="Calculate your loan">
        No loan products found. Please create a loan product first.
      </Layout>
    );
  }

  const initialProduct = loanProducts[0] as ILoanProduct;

  return (
    <Layout title="Loan Calculator" subtitle="Calculate your loan">
      {isLoanProductsLoading || isFetching ? (
        <p>Loading...</p>
      ) : (
        <div className={classes.container}>
          <CalculatorLoan includeRiskAssessment={true} loanProductId={initialProduct?.id} />
        </div>
      )}
    </Layout>
  );
};

export default AdminLoanCalculator;
