import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import { common, downloadDocument, formatDate, getFileExtension, getMimeType } from '@nayla/common';

const headCells = [
  { id: 'date', label: 'Date Provided' },
  { id: 'link', label: 'Document' },
];

const FilesTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loanApplication);
  const documents = data?.documents ?? [];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}>{headCell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.length > 0 ? (
            documents.map((document, index: number) => {
              const fileExtension = getFileExtension(document.originalDocumentName);
              return (
                <TableRow key={index}>
                  <TableCell>{formatDate(document.createdAt)}</TableCell>
                  <TableCell>
                    {' '}
                    <a
                      href="#"
                      onClick={() =>
                        downloadDocument(
                          `${common.endpoints.path.DOCUMENT_ENDPOINT}/${document.id}`,
                          document.originalDocumentName,
                          getMimeType(fileExtension),
                        )
                      }
                    >
                      {document.originalDocumentName ?? `Untitled ${index}`}
                    </a>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <div className={classes.noDocuments}>
              <p>User hasn’t added any documents </p>
            </div>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilesTab;
