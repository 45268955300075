import { FC, useState } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import {
  formatDate,
  LOAN_APPLICATION_LOOKUPS,
  loanApplicationQueryKeys,
  LoanApplicationState,
  queryClient,
  useLookupsQuery,
  useRejectLoanApplicationMutation,
  useUnderwriteLoanApplicationMutation,
} from '@nayla/common';
import Button from '@stryberventures/gaia-react.button';
import adminVocab from '@/vocabulary';
import { enqueueSnackbar } from 'notistack';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';
import { ModalDialog } from '@/components/Dialog';

const ApplicationTab: FC = () => {
  const { data: selectOptions } = useLookupsQuery({ types: LOAN_APPLICATION_LOOKUPS });
  const classes = useStyles();
  const [confirmRejectModalShown, setConfirmRejectModalShown] = useState(false);
  const [confirmAcceptModalShown, setConfirmAcceptModalShown] = useState(false);
  const data = useAtomValue(loanApplication);

  const fullName = data?.userSnapshot?.firstName + ' ' + data?.userSnapshot?.lastName;
  const profession =
    selectOptions?.PROFESSION_OPTIONS.find((op) => op.value === data?.userSnapshot?.profession)?.label ?? adminVocab.na;
  const education =
    selectOptions?.EDUCATION_OPTIONS.find((op) => op.value === data?.userSnapshot?.education)?.label ?? adminVocab.na;

  const businessName = data?.businessSnapshot?.companyName ?? adminVocab.na;
  const dateFounded = formatDate(data?.businessSnapshot?.registrationDate?.toString()) ?? adminVocab.na;
  const industry =
    selectOptions?.INDUSTRY_OPTIONS.find((op) => op.value === data?.businessSnapshot?.industry)?.label ?? adminVocab.na;
  const location = selectOptions?.LOCATION_OPTIONS.find(
    (op) => op.value === data?.businessSnapshot?.locationName,
  )?.label;
  const yearlyRevenue =
    selectOptions?.YEARLY_REVENUE_OPTIONS.find((op) => op.value === data?.businessSnapshot?.yearlyRevenue)?.label ??
    adminVocab.na;
  const employeeCount =
    selectOptions?.EMPLOYEE_COUNT_OPTIONS.find((op) => op.value === data?.businessSnapshot.employeeCount)?.label ??
    adminVocab.na;
  const purpose = selectOptions?.LOAN_PURPOSE_OPTIONS.find((op) => op.value === data?.purpose)?.label ?? adminVocab.na;

  const { mutate: rejectLoanApplication } = useRejectLoanApplicationMutation({
    onSettled: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(data?.id, loanApplicationDetailViewExpandArray),
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Loan Application Rejected', { variant: 'success' });
    },
    onError: (error: any) => {
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const { mutate: moveToUnderwriting } = useUnderwriteLoanApplicationMutation({
    onSettled: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(data?.id, loanApplicationDetailViewExpandArray),
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Loan Application Approved', { variant: 'success' });
    },
    onError: (error: any) => {
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <p style={{ fontWeight: 600, fontSize: 20 }}>{adminVocab.users.userInfoTitle}</p>
        {data?.userSnapshot ? (
          <dl className={classes.listContainer}>
            <dt className={classes.listTerm}>{adminVocab.users.properties.username}:</dt>
            <dd>{fullName}</dd>
            <dt className={classes.listTerm}>{adminVocab.users.properties.profession}:</dt>
            <dd>{profession}</dd>
            <dt className={classes.listTerm}>{adminVocab.users.properties.education}:</dt>
            <dd>{education}</dd>
            <dt className={classes.listTerm}>{adminVocab.users.properties.isPep}:</dt>
            <dd>{data.user.isPep ? 'Yes' : 'No'}</dd>
          </dl>
        ) : (
          <p>{adminVocab.loanApplication.noInfoSubmitted}</p>
        )}
      </div>
      <div className={classes.info}>
        <p style={{ fontWeight: 600 }}>{adminVocab.businesses.businessInfoTitle}</p>
        {data?.businessSnapshot ? (
          <>
            <p>
              {adminVocab.businesses.properties.name}: {businessName}
            </p>
            <p>
              {adminVocab.businesses.properties.dateFounded}: {dateFounded}
            </p>
            <p>
              {adminVocab.businesses.properties.industry}: {industry}
            </p>
            <p>
              {adminVocab.businesses.properties.location}: {location}
            </p>
            <p>
              {adminVocab.businesses.properties.yearlyRevenue}: {yearlyRevenue}
            </p>
            <p>
              {adminVocab.businesses.properties.employeeCount}: {employeeCount}
            </p>
            <p>
              {adminVocab.businesses.properties.purpose}: {purpose}
            </p>
          </>
        ) : (
          <>{adminVocab.loanApplication.noInfoSubmitted}</>
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          disabled={data?.state !== LoanApplicationState.PARTIALLY_COMPLETE}
          onClick={() => setConfirmAcceptModalShown(true)}
          className={classes.actionButton}
        >
          {adminVocab.loanApplication.acceptApplication}
        </Button>
        <Button
          disabled={data?.state !== LoanApplicationState.PARTIALLY_COMPLETE}
          onClick={() => setConfirmRejectModalShown(true)}
          className={`${classes.actionButton} ${classes.rejectButton}`}
        >
          {adminVocab.loanApplication.rejectApplication}
        </Button>
      </div>
      {confirmRejectModalShown && (
        <ModalDialog
          open={confirmRejectModalShown}
          setOpen={setConfirmRejectModalShown}
          title={adminVocab.loanApplication.confirmRejection}
          onSubmit={() => {
            setConfirmRejectModalShown(false);
            rejectLoanApplication({
              id: data?.id ?? '',
              rejectionReason: adminVocab.placeholders.manualRejectionReason,
            });
          }}
        >
          {adminVocab.loanApplication.rejectDescription}
        </ModalDialog>
      )}
      {confirmAcceptModalShown && (
        <ModalDialog
          open={confirmAcceptModalShown}
          setOpen={setConfirmAcceptModalShown}
          title={adminVocab.loanApplication.confirmAcceptance}
          onSubmit={() => {
            setConfirmAcceptModalShown(false);
            moveToUnderwriting(data?.id);
          }}
        >
          {adminVocab.loanApplication.acceptDescription}
        </ModalDialog>
      )}
    </div>
  );
};

export default ApplicationTab;
