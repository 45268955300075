import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import {
  formatDate,
  getTagColorByLoanState,
  LoanState,
  numberWithCommas,
  useLoanApplicationQuery,
} from '@nayla/common';
import adminVocab from '@/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import vocab from '@nayla/vocabulary';
import { loan } from '@/store/loan';

// interface IProps {
//   summary?: boolean;
// }

const LoanOverview: FC = () => {
  const classes = useStyles();
  const loanData = useAtomValue(loan);
  const { data: loanApplication } = useLoanApplicationQuery(loanData?.loan.loanApplication?.id, ['business', 'user']);

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <h4 className={classes.businessName}>{loanApplication?.business?.companyName ?? ''} </h4>
        <div>{`${adminVocab.loanApplication.applicationID} ${loanApplication?.externalId ?? ''}`}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.contentColumn}>
          <p>{adminVocab.loans.originalAmount}:</p>
          <p className={classes.loanTerms}>
            {loanData?.loan.offer.amount ? numberWithCommas(loanData?.loan.offer.amount) : adminVocab.na}
          </p>
          <div>{`${adminVocab.loans.start}: ${formatDate(loanData?.loan?.offer.createdAt)}`}</div>
          <p>{`${adminVocab.loans.term}: ${loanData?.loan?.offer?.terms}`}</p>
        </div>
        <div className={classes.contentColumn}>
          <p>{adminVocab.loans.remainingBalance}:</p>
          <p className={classes.loanTerms}>{numberWithCommas(loanData?.loan.remainingBalance) ?? adminVocab.na}</p>
          <div>{`${adminVocab.loans.nextPayment}: ${
            loanData?.loan.nextPaymentDate ? formatDate(loanData?.loan.nextPaymentDate) : adminVocab.na
          }`}</div>
          <p>{`${adminVocab.loans.remainingInstallments}: ${loanData?.loan.remainingInstalments}`}</p>
        </div>
        <div className={classes.contentColumn}>
          {loanData?.loan?.state ? (
            <>
              <p>{`${adminVocab.loans.status}:`}</p>
              <Tag className={classes.chip} color={getTagColorByLoanState(loanData.loan.state as LoanState)}>
                {loanData?.loan?.state ? vocab().loanState[loanData.loan.state] : ''}
              </Tag>
            </>
          ) : (
            <span></span>
          )}
          <p>{`${adminVocab.loans.properties.cr}: ${loanApplication?.business?.commercialRegistrationNumber}`}</p>
          <div>{`${adminVocab.loans.properties.debtProvider}: ${adminVocab.na}`}</div>
          <p>{`${adminVocab.loans.properties.dp}: ${adminVocab.na}`}</p>
        </div>
      </div>
    </div>
  );
};

export default LoanOverview;
