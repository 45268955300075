import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanProduct } from '@/store/loan-product';
import vocab from '@nayla/vocabulary';
import { IRiskCategory, RiskCategoryEnum } from '@nayla/common';

const DetailsTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loanProduct);
  const riskCategories: IRiskCategory[] = data?.riskCategories ?? [];

  return (
    <div className={classes.details}>
      <div>
        <p style={{ fontWeight: 600 }}>Risk Categories: </p>
        {riskCategories
          .sort((a, b) => parseFloat(a.value) - parseFloat(b.value))
          .map((riskCategory: IRiskCategory) => (
            <p>
              {vocab().loanProducts.riskCategories?.[riskCategory?.name as RiskCategoryEnum]}: {riskCategory.value}
            </p>
          ))}
      </div>
      <div>
        <p style={{ fontWeight: 600 }}>Eligibility Criteria: </p>
        <p>
          Entity Legal Formed: A Saudi Arabia based entity formed under Saudi Laws and Regulations and operating in the
          Saudi market.
        </p>
        <p>Owner Age: Minimum 22 and Maximum 70 (years old).</p>
        <p>Business Age: Minimum 1 year (Due to CR issuance).</p>
        <p>Business Operation Period: Minimum 6 months of operation.</p>
        <p>Minimal Turnover: 100,000 SAR</p>
        <p>Maximum Turnover: 3,000,000 SAR</p>
      </div>
    </div>
  );
};

export default DetailsTab;
