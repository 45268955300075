import { FC } from 'react';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import useStyles from './styles';
import { DisbursementState, LoanApplicationState, useContractsPerLoanApplicationQuery } from '@nayla/common';
import ContractsTable from '@/components/ContractsTable';
import adminVocab from '@/vocabulary';

const ContractTab: FC = () => {
  const loanApp = useAtomValue(loanApplication);
  const { data: contracts } = useContractsPerLoanApplicationQuery(loanApp?.id);
  const classes = useStyles();
  const disbursementState: DisbursementState | string =
    loanApp?.state === LoanApplicationState.DISBURSE ? loanApp?.subState : adminVocab.na;

  if (disbursementState === DisbursementState.SUCCESSFUL) {
    return <div className={classes.noContent}>{adminVocab.loanApplication.loanDisbursedSuccess}</div>;
  }

  return (
    <div className={classes.container}>
      <ContractsTable contracts={contracts} />
    </div>
  );
};

export default ContractTab;
