import { FC, useEffect } from 'react';
import useStyles from './styles';
import { useParams } from 'react-router-dom';
import { useLoanProductQuery } from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import LoanProductCard from '@/components/LoanProduct';
import LoanProductTabs from '@/screens/LoanProducts/detail/tabs';
import { useSetAtom } from 'jotai';
import { loanProduct } from '@/store/loan-product';

const LoanProductDetail: FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data } = useLoanProductQuery(id, ['riskCategories']);
  const setLoanProduct = useSetAtom(loanProduct);

  useEffect(() => {
    setLoanProduct(data);
  }, [data, setLoanProduct]);

  return (
    <Layout title={adminVocab.loanProducts.detailTitle} subtitle={data?.name}>
      {data && (
        <div className={classes.container}>
          <LoanProductCard editEnabled={true} loanProduct={data} />
          <LoanProductTabs />
        </div>
      )}
    </Layout>
  );
};

export default LoanProductDetail;
