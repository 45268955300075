import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { userProfile } from '@/store/client-profile';
import adminVocab from '@/vocabulary';
import { formatDate, numberWithCommas } from '@nayla/common';

const UserProfileOverview: FC = () => {
  const classes = useStyles();
  const userData = useAtomValue(userProfile);
  const totalBorrowed = userData?.loans?.reduce((acc, loan) => acc + parseInt(loan.offer.amount), 0);
  const riskCategory = adminVocab.na;
  const fullName = userData?.firstName + ' ' + userData?.lastName;
  const activeLoansNum = userData?.loans?.length ?? 0;

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <h4 className={classes.businessName}>{fullName ?? userData?.email} </h4>
        <div>{`${adminVocab.userProfiles.properties.ID}: ${userData?.idNumber ?? ''}`}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.contentColumn}>
          <p>{adminVocab.userProfiles.properties.totalBorrowedSAR}:</p>
          <p className={classes.loanTerms}>{totalBorrowed ? numberWithCommas(totalBorrowed) : adminVocab.na}</p>
          <div>{`${adminVocab.userProfiles.properties.clientSince}: ${formatDate(userData?.createdAt)}`}</div>
        </div>
        <div className={classes.contentColumn}>
          <p>{adminVocab.userProfiles.properties.riskCategory}:</p>
          <p className={classes.loanTerms}>{riskCategory}</p>
          <div>{`${adminVocab.userProfiles.properties.activeLoans}: ${activeLoansNum}`}</div>
        </div>
        <div className={classes.contentColumn}>
          <p>
            {adminVocab.userProfiles.properties.type}: {adminVocab.userProfiles.type}
          </p>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default UserProfileOverview;
