import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(20),
  },
});
