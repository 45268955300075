import { FC } from 'react';
import { useAtomValue } from 'jotai';
import useStyles from './styles';
import { loan } from '@/store/loan';
import { useLoanApplicationQuery } from '@nayla/common';
import adminVocab from '@/vocabulary';

const LoanContactInfo: FC = () => {
  const classes = useStyles();
  const loanData = useAtomValue(loan);
  const { data: loanApplication } = useLoanApplicationQuery(loanData?.loan.loanApplication?.id, ['business', 'user']);

  const fullName = loanApplication?.user?.firstName + ' ' + loanApplication?.user?.lastName;
  const phoneNumber = loanApplication?.user?.phone ?? adminVocab.na;
  const email = loanApplication?.user?.email ?? adminVocab.na;

  return (
    <div className={classes.container}>
      <p>Contact Info: </p>
      <div className={classes.userData}>
        <p>
          <span style={{ fontWeight: 600 }}>UserName:</span> {fullName}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Mobile Number:</span> {phoneNumber}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Email:</span> {email}
        </p>
      </div>
    </div>
  );
};

export default LoanContactInfo;
