import { createUseStyles } from 'react-jss';
import { toRem } from '@stryberventures/gaia-react.theme';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  title: {
    color: '#000',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '40px', // 125%
    letterSpacing: '-0.64px',
  },
  description: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    color: '#D0D5DD',
    marginLeft: toRem(42),
    justifyContent: 'space-between',
    height: toRem(300),
  },
});
