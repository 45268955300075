import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

const useStyles = createUseStyles({
  profileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: 10,
  },
  accountBtn: {
    textDecoration: 'none',
    color: theme.colors.contrast.black,
    fontSize: 18,
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.primary.main500,
    },
  },
  email: {
    fontFamily: theme.font,
    fontSize: 12,
    lineHeight: '14px',
    color: theme.colors.neutralGray.main500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  profilePopup: {
    width: toRem(343),
    position: 'fixed',
    margin: toRem(-281) + ' 0 0 ' + toRem(20),
    backgroundColor: theme.colors.background.white,
    boxShadow: '0px 2px 13px rgb(0 0 0 / 9%)',
    borderRadius: 10,
    zIndex: 2,
    // padding: '20px 25px',
    display: 'flex',
    flexDirection: 'column',
    '& hr': {
      width: '100%',
      border: '0.5px solid',
      borderColor: theme.colors.neutralGray.light100,
    },
  },
  popupTitle: {
    color: theme.colors.contrast.black,
    fontFamily: theme.font,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19px',
    marginLeft: toRem(20),
    marginTop: toRem(20),
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 20,
    margin: '18px 0',
  },
  userNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  userNameContainerPopup: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginLeft: toRem(20),
  },
  textOverflow: {
    width: toRem(150),
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: toRem(14),
  },
});

export default useStyles;
