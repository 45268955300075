import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: toRem(60),
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
});
