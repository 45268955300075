import { ReactNode } from 'react';
import Dialog from '@stryberventures/gaia-react.dialog';
import Text from '@/components/Text';
import Button from '@stryberventures/gaia-react.button';
import { CloseIcon } from '@stryberventures/gaia-react.icons';
import useStyles from './styles';

interface IFormDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  children?: ReactNode;
  title: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  onSubmit: () => void;
  id?: string;
}

export const ModalDialog = ({
  open,
  setOpen,
  title,
  description = '',
  cancelText,
  submitText,
  onSubmit,
  children,
  id,
}: IFormDialogProps) => {
  const classes = useStyles(),
    handleClose = () => {
      setOpen(false);
    };

  return (
    <Dialog className={classes.dialog} id={id} open={open} onClose={handleClose}>
      <Dialog.Title>
        <Text variant="h4" weight="semiBold">
          {title}
        </Text>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Dialog.Title>
      <Dialog.Text>
        <Text variant="body1">{description}</Text>
        <div>{children}</div>
      </Dialog.Text>
      <Dialog.Actions>
        <Button onClick={handleClose} size="small" variant="outlined">
          {cancelText ?? 'cancel'}
        </Button>
        <Button onClick={onSubmit} size="small">
          {submitText ?? 'submit'}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
