import * as React from 'react';
import Text from '@/components/Text';
import adminVocab from '@/vocabulary';
import useStyles from './styles';
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableSection,
} from '@stryberventures/gaia-react.table';
import { IOption } from '@stryberventures/gaia-react.combobox';
import { useNavigate } from 'react-router-dom';
import {
  formatDate,
  getComparator,
  getTagColorByLoanState,
  ILoan,
  LoanState,
  numberWithCommas,
  Order,
  stableSort,
  useLoansQuery,
} from '@nayla/common';
import { EnhancedTableHead } from '@nayla/ui';
import Tag from '@stryberventures/gaia-react.tag';
import { Dayjs } from 'dayjs';
import Chip from '@stryberventures/gaia-react.chip';
import { CloseCircleIcon } from '@stryberventures/gaia-react.icons';
import Button from '@stryberventures/gaia-react.button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LoansFilters } from '@/components/LoanBookTable/LoansFilters';
import { IHeadCell } from '@nayla/ui/components/TableHead';
import { useEffect } from 'react';

export interface ILoansFilters {
  from: Dayjs | null;
  to: Dayjs | null;
  state: LoanState | null;
}

const headCells: readonly IHeadCell[] = [
  {
    id: 'businessName',
    numeric: false,
    disablePadding: true,
    label: adminVocab.loans.properties.businessName,
  },
  {
    id: 'businessCR',
    numeric: false,
    disablePadding: false,
    label: adminVocab.loans.properties.businessCR,
  },
  {
    id: 'loanAmount',
    numeric: false,
    disablePadding: false,
    label: adminVocab.loans.properties.loanAmount,
  },
  {
    id: 'loanId',
    numeric: false,
    disablePadding: false,
    label: adminVocab.loans.properties.loanId,
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: adminVocab.loans.properties.startDate,
  },
  {
    id: 'loanStatus',
    numeric: false,
    disablePadding: false,
    label: adminVocab.loans.properties.state,
  },
];

export const LoanBookTable = () => {
  const navigate = useNavigate(),
    classes = useStyles(),
    [query, setQuery] = React.useState(''),
    { data: rows } = useLoansQuery(query),
    [order, setOrder] = React.useState<Order>('asc'),
    [orderBy, setOrderBy] = React.useState<keyof ILoan>('id'),
    [selected] = React.useState<readonly string[]>([]),
    [page, setPage] = React.useState(0),
    [filtersModalShown, setFiltersModalShown] = React.useState(false),
    [filters, setFilters] = React.useState<ILoansFilters>({
      from: null,
      to: null,
      state: null,
    }),
    [rowsPerPage, setRowsPerPage] = React.useState(10),
    handleRequestSort = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, property: keyof ILoan) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [order, orderBy, page, rowsPerPage],
    ),
    handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = React.useCallback(
      (option: IOption) => {
        const updatedRowsPerPage = parseInt(option.label, 10);
        setRowsPerPage(updatedRowsPerPage);
        setPage(0);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [order, orderBy],
    ),
    isSelected = (name: string) => selected.indexOf(name) !== -1,
    // Avoid a layout jump when reaching the last page with empty rows.
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    const q = new URLSearchParams();
    if (filters.from) {
      q.append('createdAtFrom', filters.from.format());
    }
    if (filters.to) {
      q.append('createdAtTo', filters.to.format());
    }
    if (filters.state) {
      q.append('states[]', filters.state);
    }
    setQuery(q.toString());
  }, [filters]);

  return (
    <>
      <TableContainer>
        {!!selected.length && (
          <TableSection>
            <Text variant="components1">{selected.length} Items Selected</Text>
          </TableSection>
        )}
        <div className={classes.filterContainer}>
          {filters.from && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, from: null });
                  }}
                />
              }
            >
              {'From: ' + (filters.from as any)?.format()?.substring(0, 10)}
            </Chip>
          )}
          {filters.to && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, to: null });
                  }}
                />
              }
            >
              {'To: ' + (filters.to as any)?.format()?.substring(0, 10)}
            </Chip>
          )}
          {filters.state && (
            <Chip
              className={classes.filterItem}
              color="default"
              iconRight={
                <CloseCircleIcon
                  fill={'red'}
                  onClick={() => {
                    setFilters({ ...filters, state: null });
                  }}
                />
              }
            >
              {'State: ' + adminVocab.loans.states[filters.state]}
            </Chip>
          )}
          <Button
            className={classes.toolbarIcon}
            onClick={() => setFiltersModalShown(true)}
            iconLeft={() => <FilterListIcon />}
            type="button"
            shape="round"
            variant="outlined"
          >
            {adminVocab.table.actions.filters}
          </Button>
        </div>
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            headcells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: ILoan, index) => {
                const isItemSelected = isSelected(row.id),
                  labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    className={classes.tableRow}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    onClick={() => {
                      navigate(`/loan/${row.id}`, { state: row });
                    }}
                  >
                    <TableCell>{row?.business?.companyName}</TableCell>
                    <TableCell>{row?.business?.commercialRegistrationNumber}</TableCell>
                    <TableCell>{numberWithCommas(row?.offer?.amount)}</TableCell>
                    <TableCell id={labelId}>{row?.externalId}</TableCell>
                    <TableCell>{formatDate(row.createdAt)}</TableCell>
                    <TableCell>
                      <Tag color={getTagColorByLoanState(row.state)}>{adminVocab.loans.states[row.state]}</Tag>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {filtersModalShown && (
        <LoansFilters
          filters={filters}
          setFilters={setFilters}
          title={adminVocab.loans.filtersTitle}
          open={filtersModalShown}
          setOpen={setFiltersModalShown}
        />
      )}
    </>
  );
};
