import { FC } from 'react';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import useStyles from './styles';
import Tag from '@stryberventures/gaia-react.tag';
import adminVocab from '@/vocabulary';

const PaymentTab: FC = () => {
  const loanApp = useAtomValue(loanApplication);
  const classes = useStyles();
  const business = loanApp?.business;
  const paymentProvided = business && business?.hyperPayRegistrationId && business?.paymentBrand;
  return (
    <div className={classes.container}>
      <div className={classes.paymentInfo}>
        <span style={{ fontWeight: 600 }}>Payment Information:</span>
        <Tag color={paymentProvided ? 'success' : 'neutralGray'}>
          {paymentProvided ? adminVocab.loanApplication.payment.provided : adminVocab.loanApplication.payment.none}
        </Tag>
      </div>
      <div className={classes.description}>
        {paymentProvided ? (
          <>
            <p>{adminVocab.loanApplication.payment.hyperPayProvidedDescription}</p>
            <p>{adminVocab.loanApplication.payment.creditCardProvidedDescription}</p>
          </>
        ) : (
          <p>{adminVocab.loanApplication.payment.notProvidedDescription}</p>
        )}
      </div>
    </div>
  );
};

export default PaymentTab;
