import { toRem } from '@stryberventures/gaia-react.theme';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  closeIcon: {
    width: 16,
    height: 16,
    float: 'right',
    cursor: 'pointer',
  },
  dialog: {
    maxWidth: 'unset !important',
    minWidth: toRem(600),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  buttonCancel: {
    marginRight: toRem(10),
  },
});
