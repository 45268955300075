import { createUseStyles } from 'react-jss';
import theme, { toRem } from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  filterCaption: {
    color: theme.colors.neutralGray.dark600,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: toRem(20),
  },
  datePickers: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: toRem(25),
  },
});
