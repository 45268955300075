import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(20),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editButton: {
    padding: '0 !important',
    float: 'right',
    marginBottom: toRem(20),
    fontWeight: 600,
  },
});
