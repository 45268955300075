import { IFetchRequest, IInstalment } from '../../interfaces';
import { common } from '../index';

export const getInstalment = (
  id: string | undefined,
  { fetcher, options, expand }: IFetchRequest,
): Promise<IInstalment> => {
  if (!id) throw new Error('id is required');

  let queryString = '';
  if (expand && expand.length > 0) {
    queryString = expand.map((expandParam) => `expand[]=${expandParam}`).join('&');
  }

  return fetcher.get(`${common.endpoints.path.INSTALMENT_ENDPOINT}/${id}?${queryString}`, options);
};

export const getInstalments = ({ fetcher, options, query }: IFetchRequest): Promise<IInstalment[]> => {
  return fetcher.get(`${common.endpoints.path.INSTALMENT_ENDPOINT}${query ? '?' + query : ''}`, options);
};

export const getInstalmentsCSV = ({ fetcher }: IFetchRequest): Promise<string> => {
  return fetcher.getCSV(`${common.endpoints.path.INSTALMENT_ENDPOINT}/export-csv`);
};
