import { FC } from 'react';
import Layout from '@nayla/ui/components/Layout';

const Home: FC = () => {
  return (
    <Layout title="Home Page" subtitle="Dashboard">
      It is empty here. Please come back later:)
    </Layout>
  );
};

export default Home;
