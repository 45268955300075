import { FC, useState } from 'react';
import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import Tabs from '@stryberventures/gaia-react.tabs';
import HistoryTab from '@/screens/Loans/detail/tabs/history';
import ApplicationInfoTab from '@/screens/Loans/detail/tabs/application-info';
import InstalmentsStatusTab from '@/screens/Loans/detail/tabs/instalment-status';

const TAB_IDS = {
  INSTALMENTS: 'instalments',
  APPLICATION_INFO: 'applicationInfo',
  LOGS: 'logs',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.INSTALMENTS,
    label: adminVocab.loans.tabs.instalments,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.APPLICATION_INFO,
    label: adminVocab.loans.tabs.applicationInfo,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.LOGS,
    label: adminVocab.loans.tabs.logs,
  },
];

const LoanTabs: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TAB_IDS.INSTALMENTS);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>
        {activeTab === TAB_IDS.INSTALMENTS && <InstalmentsStatusTab />}
        {activeTab === TAB_IDS.APPLICATION_INFO && <ApplicationInfoTab />}
        {activeTab === TAB_IDS.LOGS && <HistoryTab />}
      </div>
    </div>
  );
};

export default LoanTabs;
