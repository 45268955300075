import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: toRem(50),
  },
  calculatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: toRem(100),
    justifyContent: 'center',
    alignItems: 'start',
  },
  calculatorColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(5),
  },
  calculatorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: toRem(320),
    gap: toRem(15),
  },
  offerTable: {
    width: '100%',
  },
  divider: {
    display: 'flex',
    width: toRem(290),
    height: toRem(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutralGray.light200,
  },
  tagCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  outcomeTitle: {
    color: theme.colors.contrast.black,
    fontWeight: 600,
    fontSize: toRem(20),
    lineHeight: toRem(28),
  },
  sendOfferBtn: {
    padding: `0 !important`,
    width: toRem(186),
    height: toRem(40),
  },
  noContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: toRem(30),
  },
});
