import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import adminVocab from '@/vocabulary';
import { FC, useState } from 'react';
import useStyles from './styles';
import Tabs from '@stryberventures/gaia-react.tabs';
import BusinessInformationTab from '@/screens/client-profiles/BusinessProfiles/detail/tabs/information';
import BusinessAMLTab from '@/screens/client-profiles/BusinessProfiles/detail/tabs/aml';

const TAB_IDS = {
  INFO: 'info',
  KYB: 'kyb',
  AML: 'aml',
  DOCUMENTS: 'documents',
  LOAN_HISTORY: 'loan-history',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.INFO,
    label: adminVocab.businessProfiles.tabs.kyb,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.AML,
    label: adminVocab.businessProfiles.tabs.aml,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.LOAN_HISTORY,
    label: adminVocab.businessProfiles.tabs.loanHistory,
  },
];

const BusinessProfileTabs: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TAB_IDS.INFO);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>{activeTab === TAB_IDS.INFO && <BusinessInformationTab />}</div>
      <div className={classes.content}>{activeTab === TAB_IDS.AML && <BusinessAMLTab />}</div>
    </div>
  );
};

export default BusinessProfileTabs;
