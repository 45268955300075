import { useRef, useState } from 'react';
import useStyles from './styles';
import UserProfileMenuItems from '../UserProfileMenuItems';
import { ROLE_IDS, useMeQuery, useOutsideClick } from '@nayla/common';
import vocab from '@nayla/vocabulary';

interface IUserProfileProps {
  isOpen?: boolean;
}

export const UserProfile = ({ isOpen }: IUserProfileProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const profileWrapperRef = useRef(null);
  const profilePopupRef = useRef(null);
  useOutsideClick(profilePopupRef, (e) => {
    const element = e.target as HTMLElement;
    const insideDialogClicked =
      element?.closest('div[id*="change-password-modal"]') || element.tagName.toLowerCase() === 'button';
    return (
      !insideDialogClicked && !(profileWrapperRef.current as unknown as HTMLElement).contains(element) && setOpen(false)
    );
  });

  const { data: user, isLoading } = useMeQuery();

  if (isLoading || !user) {
    return null;
  }

  const isAdmin = user?.roles?.find((role) => role === ROLE_IDS.ADMIN);

  return (
    <>
      <div
        onClick={(event) => {
          event.preventDefault();
          setOpen(!open);
        }}
        ref={profileWrapperRef}
        className={classes.profileWrapper}
      >
        {/* user?.username ?? vocab.menuItems.account */}
        <span className={classes.accountBtn}>
          <img style={{ width: '48px', height: '48px', objectFit: 'contain' }} src="/default_user.png" />
        </span>
        {isOpen && (
          <div className={classes.userNameContainer}>
            <span className={classes.textOverflow}>{user?.username}</span>
            <span className={classes.email}>{user?.email}</span>
          </div>
        )}
      </div>
      {open && (
        <div ref={profilePopupRef} className={classes.profilePopup}>
          <span className={classes.popupTitle}>{vocab().menuItems.account}</span>
          <div className={classes.userInfoContainer}>
            <div className={classes.userNameContainerPopup}>
              <span>{`${user?.username} ${isAdmin ? '(Admin)' : '(User)'}`}</span>
              <span className={classes.email}>{user?.email}</span>
            </div>
          </div>
          <UserProfileMenuItems setProfilePopupState={(state: boolean) => setOpen(state)} />
        </div>
      )}
    </>
  );
};

export default UserProfile;
