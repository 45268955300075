import * as React from 'react';
import { TableCell, TableHead, TableRow, TableSortButton } from '@stryberventures/gaia-react.table';
import { IUser, Order } from '@nayla/common';

export interface IEnhancedTableProps {
  headcells: readonly IHeadCell[];
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, property: any) => void;
  onSelectAllClick?: (event: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export interface IHeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  disableSort?: boolean;
}

export const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, headcells, onRequestSort } = props,
    createSortHandler =
      (property: keyof IUser) => (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
        onRequestSort(event, property);
      };

  return (
    <TableHead>
      <TableRow>
        {/* TODO turn on the checkbox in case multi-select is required */}
        {/* {!!onSelectAllClick && ( */}
        {/*   <TableCell> */}
        {/*     <Checkbox */}
        {/*       disabled */}
        {/*       color="primary" */}
        {/*       indeterminate={numSelected > 0 ? numSelected < rowCount : undefined} */}
        {/*       checked={rowCount > 0 && numSelected === rowCount} */}
        {/*       onChange={onSelectAllClick} */}
        {/*     /> */}
        {/*   </TableCell> */}
        {/* )} */}
        {headcells.map((headCell) => (
          <TableCell key={headCell.id}>
            {headCell.label}
            {!headCell.disableSort && (
              <TableSortButton
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
