import { FC } from 'react';
import useStyles from './styles';
import Layout from '@nayla/ui/components/Layout';
import { useSettingsQuery } from '@nayla/common/rest/queries/settings';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import { ISettings } from '@nayla/common';
import adminVocab from '@/vocabulary';
import Button from '@stryberventures/gaia-react.button';
import * as React from 'react';
import { EditIcon } from '@stryberventures/gaia-react.icons';
import theme from '@nayla/ui/styles';
import { AdminSettingsForm } from '@/components/AdminSettingsForm';

const headCells = [
  { id: 'parameter', label: 'Configuration' },
  { id: 'value', label: 'Value' },
];

const excludeKeys: Array<keyof ISettings> = ['id', 'createdAt', 'updatedAt'];

const AdminSettings: FC = () => {
  const classes = useStyles();
  const { data: settings } = useSettingsQuery();
  const [updateFormShown, setUpdateFormShown] = React.useState(false);

  if (!settings) {
    return null;
  }

  return (
    <Layout title={adminVocab.adminSettings.title} subtitle={adminVocab.adminSettings.subtitle}>
      <div className={classes.header}>
        <Button
          onClick={() => setUpdateFormShown(true)}
          className={classes.updateBtn}
          iconLeft={() => <EditIcon fill={theme.colors.primary.main500} />}
          type="button"
          variant="outlined"
          shape="round"
        >
          {adminVocab.adminSettings.updateSettings}
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.keys(settings) as Array<keyof ISettings>)
              .filter((setting) => !excludeKeys.includes(setting))
              .map((key: keyof ISettings) => {
                return (
                  <TableRow key={key}>
                    <TableCell>{(adminVocab.adminSettings.properties as unknown as Partial<ISettings>)[key]}</TableCell>
                    <TableCell>{settings[key] ? settings[key] : adminVocab.na}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {updateFormShown && (
        <AdminSettingsForm adminSettings={settings} open={updateFormShown} setOpen={setUpdateFormShown} />
      )}
    </Layout>
  );
};

export default AdminSettings;
