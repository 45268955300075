import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { userProfile } from '@/store/client-profile';
import { formatDate } from '@nayla/common';
import adminVocab from '@/vocabulary';

const UserInformationTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(userProfile);
  const fullName = data?.firstName + ' ' + data?.lastName;
  const idNumber = data?.idNumber ?? adminVocab.na;
  const profession = data?.profession ?? adminVocab.na;
  const education = data?.education ?? adminVocab.na;
  const birthDate = data?.birthdate ? formatDate(data.birthdate) : adminVocab.na;

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <p>
          {adminVocab.users.properties.name}: {fullName}
        </p>
        <p>
          {adminVocab.users.properties.idNumber}: {idNumber}
        </p>
        <p>
          {adminVocab.users.properties.birthdate}: {birthDate}
        </p>
        <p>
          {adminVocab.users.properties.profession}: {profession}
        </p>
        <p>
          {adminVocab.users.properties.education}: {education}
        </p>
      </div>
    </div>
  );
};

export default UserInformationTab;
