import { FC } from 'react';
import useStyles from '../SignIn/styles';
import SignUpComponent from '@/components/SignUpComponent';

const SignUp: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SignUpComponent />
    </div>
  );
};

export default SignUp;
