import { ILoanApplication } from './loan-application';

export enum OfferState {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface IOffer {
  id: string;
  amount: string;
  terms: number;
  adminFees: string;
  monthlyProfitRate: string;
  loanApplication: ILoanApplication;
  state: OfferState;
  createdAt: string;
  updatedAt: string;
}

export interface INewOffer {
  amount: string;
  terms: number;
  adminFees: string;
}
