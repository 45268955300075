import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import TextArea from '@stryberventures/gaia-react.text-area';
import Button from '@stryberventures/gaia-react.button';
import { formatDate, IUser, loanQueryKeys, queryClient, useCreateNoteForLoanMutation, useMeQuery } from '@nayla/common';
import { enqueueSnackbar } from 'notistack';
import Form from '@stryberventures/gaia-react.form';
import { loan } from '@/store/loan';
import adminVocab from '@/vocabulary';
import { INote } from '@nayla/common/interfaces/note';
import dayjs from 'dayjs';

const HistoryTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loan);
  const { mutate: createNote } = useCreateNoteForLoanMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(loanQueryKeys.loan(data?.loan?.id, ['user']));
      enqueueSnackbar('Note created', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Error creating note', { variant: 'error' });
    },
  });
  const currentUser = useMeQuery();
  const getUserName = (user: IUser | null) => {
    if (!user) return adminVocab.na;
    return user.firstName + ' ' + user.lastName;
  };

  return (
    <div className={classes.container}>
      <Form
        initialValues={{
          note: '',
        }}
        onSubmit={(values) => {
          createNote({
            id: data?.loan?.id,
            note: values.note,
          });
        }}
        className={classes.commentsForm}
      >
        <p style={{ fontWeight: 600 }}>Please add any comments here:</p>
        <div>
          <TextArea name="note" placeholder="Add comment here" />
          <p style={{ float: 'right' }}>{getUserName(currentUser.data)} (Credit Risk Officer)</p>
        </div>
        <div className={classes.formAction}>
          <span></span>
          <Button type="submit" className={classes.submit} variant="outlined">
            Insert comment
          </Button>
        </div>
      </Form>

      {data && data?.loan?.notes?.length > 0 && (
        <>
          <div className={classes.divider}></div>
          <div className={classes.notes}>
            <p style={{ fontWeight: 600 }}>Activity / Notes Log</p>
            {data?.loan?.notes
              ?.sort((a: INote, b: INote) => dayjs(b?.createdAt).valueOf() - dayjs(a?.createdAt).valueOf())
              .map((note, index) => {
                return (
                  <>
                    <p style={{ fontWeight: 600 }} tag-id={index}>
                      {formatDate(note.createdAt)} - {getUserName(note.user) ?? 'System Message'}
                    </p>
                    <p>{note.text}</p>
                  </>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default HistoryTab;
