import { createStyles } from '@stryberventures/gaia-react.theme';
import { toRem } from '@nayla/ui';

export default createStyles((theme) => ({
  sidebar: {
    left: '0vw',
    height: '100%',
    width: toRem(82),
    minWidth: toRem(82),
    transition: 'width 1s, min-width 1s',
    backgroundColor: 'white',
    borderRight: '1px solid ' + theme.colors.neutralGray.light200,
    zIndex: 1,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  sidebarToggle: {
    position: 'absolute',
    top: '20%',
    right: '-60px',
    height: '60px',
    width: '60px',
    zIndex: 1,
  },
  sidebarOpen: {
    left: 0,
    width: toRem(280),
    minWidth: toRem(280),
  },
  contentOpen: {
    left: '15vw',
  },
  logo: {
    maxHeight: toRem(50),
    marginTop: toRem(32),
    cursor: 'pointer',
  },
  bigLogoContainer: {
    marginLeft: toRem(24),
    marginRight: toRem(24.47),
    marginTop: toRem(36),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bigLogo: {
    width: toRem(108.59),
  },
  smallLogoContainer: {
    marginLeft: toRem(24),
    marginRight: toRem(22.07),
    marginTop: toRem(32),
    cursor: 'pointer',
    paddingBottom: toRem(28),
  },
  profileContainer: (isSidebarOpened: boolean) => ({
    position: 'fixed',
    bottom: 0,
    left: isSidebarOpened ? toRem(36) : toRem(16),
    borderTop: '1px solid ' + theme.colors.neutralGray.light200,
    height: toRem(72),
    minHeight: toRem(72),
    display: 'flex',
    width: isSidebarOpened ? toRem(208) : toRem(50),
    overflow: 'hidden',
    zIndex: 999999,
    backgroundColor: theme.colors.contrast.white,
  }),
  buttonsContainer: {
    height: `calc(100% - ${toRem(168)})`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  iconButton: {
    marginLeft: toRem(12.5),
    marginRight: toRem(13.5),
    backgroundColor: 'white',
    borderRadius: toRem(4),
    height: toRem(56),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: toRem(12),
    '&:hover': {
      backgroundColor: theme.colors.neutralGray.light100,
    },
  },
  dashboardIcon: {
    marginLeft: toRem(16.95),
    '& svg': {
      width: toRem(20),
      height: toRem(20),
    },
  },
  searchInputContainer: {
    marginLeft: toRem(24),
    marginRight: toRem(25),
    marginTop: toRem(27),
    marginBottom: toRem(28),
  },
  buttonText: {
    paddingLeft: toRem(12),
    fontFamily: 'Inter',
    fontSize: toRem(16),
    lineHeight: '150%',
    fontWeight: 500,
    color: theme.colors.text.secondary,
  },
}));
