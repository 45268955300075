import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: toRem(20),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: toRem(10),
    fontSize: toRem(12),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(14),
    },
  },
  rejectButton: {
    height: toRem(24),
    width: toRem(130),
    fontSize: toRem(10),
    [MEDIA_QUERIES.desktop]: {
      width: toRem(194),
      fontSize: toRem(16),
    },
  },
});
