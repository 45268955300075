import { FC } from 'react';
import { useApiUsersQuery } from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { ClientProfilesTable } from '@/components/ClientProfilesTable';
import { IClientProfile } from '@/screens/client-profiles';

const UserProfiles: FC = () => {
  const { data: userProfiles, isLoading: isUserProfilesLoading } = useApiUsersQuery();

  if (isUserProfilesLoading) {
    return (
      <Layout title={adminVocab.userProfiles.title} subtitle={adminVocab.userProfiles.description}>
        <div>{adminVocab.loading}</div>
      </Layout>
    );
  }

  const clientProfiles: IClientProfile[] = [
    ...(userProfiles?.map((user) => ({
      type: 'user' as const,
      id: user.id,
      clientId: user.idNumber,
      name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : (user.email ?? user.id),
      createdAt: user.createdAt,
      fallbackId: user.email,
    })) || []),
  ];

  return (
    <Layout title={adminVocab.userProfiles.title} subtitle={adminVocab.userProfiles.description}>
      <ClientProfilesTable rows={clientProfiles} />
    </Layout>
  );
};

export default UserProfiles;
