import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import adminVocab from '@/vocabulary';
import { formatDate, numberWithCommas } from '@nayla/common';
import { businessProfile } from '@/store/client-profile';

const BusinessProfileOverview: FC = () => {
  const classes = useStyles();
  const businessData = useAtomValue(businessProfile);
  const totalBorrowed = businessData?.loans?.reduce((acc, loan) => acc + parseInt(loan.offer.amount), 0);
  const riskCategory = adminVocab.na;
  const activeLoansNum = businessData?.loans?.length ?? 0;

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <h4 className={classes.businessName}>{businessData?.companyName ?? businessData?.externalId} </h4>
        <div>{`${adminVocab.businessProfiles.properties.CR}: ${businessData?.commercialRegistrationNumber ?? ''}`}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.contentColumn}>
          <p>{adminVocab.businessProfiles.properties.totalBorrowedSAR}:</p>
          <p className={classes.loanTerms}>{totalBorrowed ? numberWithCommas(totalBorrowed) : adminVocab.na}</p>
          <div>{`${adminVocab.businessProfiles.properties.clientSince}: ${formatDate(businessData?.createdAt)}`}</div>
        </div>
        <div className={classes.contentColumn}>
          <p>{adminVocab.businessProfiles.properties.riskCategory}:</p>
          <p className={classes.loanTerms}>{riskCategory}</p>
          <div>{`${adminVocab.businessProfiles.properties.activeLoans}: ${activeLoansNum}`}</div>
        </div>
        <div className={classes.contentColumn}>
          <p>
            {adminVocab.businessProfiles.properties.type}: {adminVocab.businessProfiles.type}
          </p>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfileOverview;
