import { FC } from 'react';
import { useBusinessesQuery } from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { ClientProfilesTable } from '@/components/ClientProfilesTable';
import { IClientProfile } from '@/screens/client-profiles';

const BusinessProfiles: FC = () => {
  const { data: businessProfiles, isLoading: isBusinessProfilesLoading } = useBusinessesQuery();

  if (isBusinessProfilesLoading) {
    return (
      <Layout title={adminVocab.businessProfiles.title} subtitle={adminVocab.businessProfiles.description}>
        <div>{adminVocab.loading}</div>
      </Layout>
    );
  }

  const clientProfiles: IClientProfile[] =
    businessProfiles?.map((business) => ({
      type: 'business' as const,
      id: business.id,
      clientId: business.commercialRegistrationNumber,
      name: business.companyName,
      createdAt: business.createdAt,
      fallbackId: business.externalId,
    })) || [];

  return (
    <Layout title={adminVocab.businessProfiles.title} subtitle={adminVocab.businessProfiles.description}>
      <ClientProfilesTable rows={clientProfiles} />
    </Layout>
  );
};

export default BusinessProfiles;
