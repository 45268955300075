import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  columnContainer: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    minWidth: toRem(250),
    borderRadius: toRem(12),
    paddingBottom: toRem(34),
    backgroundColor: theme.colors.background.white,
    boxShadow: `${toRem(0)} ${toRem(4)} ${toRem(4)} ${toRem(0)} rgba(0, 0, 0, 0.10)`,
  },
  cardHeader: {
    display: 'flex',
    paddingLeft: toRem(24),
    width: toRem(274),
    height: toRem(50),
    justifyContent: 'start',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: toRem(18),
    color: theme.colors.contrast.black,
  },
  cardContent: {
    overflow: 'auto',
    height: toRem(500),
  },
  loanApplicationCard: {
    borderTop: `0.5px solid ${theme.colors.neutralGray.light200}`,
    borderBottom: `0.5px solid ${theme.colors.neutralGray.light200}`,
  },
});
