import { FC, useEffect } from 'react';
import useStyles from '@/components/Login/styles';
import useSignInStyles from '../SignIn/styles';
import adminVocab from '@/vocabulary';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import { useVerifyTokenMutation } from '@nayla/common';

const Verify: FC = () => {
  const classes = useStyles(),
    signInClasses = useSignInStyles(),
    navigate = useNavigate(),
    onSuccess = () => {
      enqueueSnackbar(adminVocab.authentication.emailVerified, { variant: 'success' });
      navigate('/');
    },
    onError = () => {
      enqueueSnackbar(adminVocab.errors.oopsSomethingWentWrong, { variant: 'error' });
      navigate('/');
    },
    { mutate: verifyToken } = useVerifyTokenMutation({
      onSuccess,
      onError,
    });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) {
      enqueueSnackbar(adminVocab.errors.oopsSomethingWentWrong, { variant: 'error' });
      navigate('/');
    } else {
      verifyToken({ verificationToken: token });
    }
  }, [navigate, verifyToken]);

  return (
    <div className={signInClasses.container}>
      <div className={classes.wrapper}>
        <div className={classes.logoWrapper}></div>
        <div className={classes.screen}>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default Verify;
