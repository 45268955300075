import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { useBusinessQuery } from '@nayla/common';
import { useSetAtom } from 'jotai';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { businessProfile } from '@/store/client-profile';
import BusinessProfileOverview from '@/screens/client-profiles/BusinessProfiles/detail/overview';
import BusinessProfileContactInfo from '@/screens/client-profiles/BusinessProfiles/detail/contact';
import BusinessProfileTabs from '@/screens/client-profiles/BusinessProfiles/detail/tabs';

const BusinessClientProfileDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data: data } = useBusinessQuery(id);
  const setBusiness = useSetAtom(businessProfile);

  useEffect(() => {
    setBusiness(data);
  }, [data, setBusiness]);

  return (
    <Layout subtitle={data?.externalId} title={adminVocab.businessProfiles.title}>
      {data && (
        <div className={classes.grid}>
          <BusinessProfileOverview />
          <BusinessProfileContactInfo />
          <BusinessProfileTabs />
        </div>
      )}
    </Layout>
  );
};

export default BusinessClientProfileDetail;
