import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterItem: {
    backgroundColor: '#CAD4FF !important',
    color: '#001D9A !important',
    marginRight: toRem(20),
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: toRem(20),
    width: '100%',
    height: toRem(76),
  },
  toolbarIcon: {
    width: 137,
    height: 40,
    marginLeft: toRem(16),
  },
});
