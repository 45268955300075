import { FC } from 'react';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { useLoanProductsQuery } from '@nayla/common';
import LoanProductCard from '@/components/LoanProduct';
import useStyles from './styles';
import Button from '@stryberventures/gaia-react.button';
import { EditIcon } from '@stryberventures/gaia-react.icons';
import theme from '@nayla/ui/styles';
import * as React from 'react';
import { LoanProductForm } from '@/components/LoanProductForm';

const LoanProducts: FC = () => {
  const { data } = useLoanProductsQuery();
  const classes = useStyles();
  const [loanProductModalShown, setLoanProductModalShown] = React.useState(false);

  return (
    <Layout title={adminVocab.menuItems.loanProducts} subtitle={adminVocab.loanProducts.description}>
      <div className={classes.actions}>
        <Button
          className={classes.editButton}
          size="medium"
          iconRight={<EditIcon fill={theme.colors.primary.main500} width={16} height={16} />}
          variant="ghost"
          onClick={() => setLoanProductModalShown(true)}
        >
          Create Product
        </Button>
      </div>
      <div className={classes.container}>
        {data.map((loanProduct) => (
          <LoanProductCard
            detailLink={`/loan-product/${loanProduct.id}`}
            loanProduct={loanProduct}
            key={loanProduct.id}
          />
        ))}
      </div>
      {loanProductModalShown && <LoanProductForm open={loanProductModalShown} setOpen={setLoanProductModalShown} />}
    </Layout>
  );
};

export default LoanProducts;
