import { createBrowserRouter, createRoutesFromElements, Navigate, Route, useLocation } from 'react-router-dom';
import React from 'react';
import Root from '@/components/Root';
import ErrorPage from '@/screens/Page404';
import SignIn from '@/screens/auth/SignIn';
import SignUp from '@/screens/auth/SignUp';
import Home from '@/screens/Home';
import LoanBook from '../screens/Loans';
import ForgotPassword from '@/screens/auth/ForgotPassword';
import NewPasswordChallenge from '@/screens/auth/NewPasswordChallenge';
import Verify from '@/screens/auth/Verify';
import ForgotPasswordTokenConfirm from '../screens/auth/ForgotPasswordTokenConfirm';
import LoanApplications from '../screens/LoanApplications';
import Instalments from '../screens/Instalments';
import LoanApplicationEdit from '@/screens/LoanApplications/detail';
import { useAtomValue } from 'jotai';
import LoanDetail from '@/screens/Loans/detail';
import { isAuthenticatedState } from '@nayla/common';
import LoanProducts from '@/screens/LoanProducts';
import AdminLoanCalculator from '@/screens/Calculator';
import Users from '@/screens/Users';
import LoanProductDetail from '@/screens/LoanProducts/detail';
import AdminSettings from '@/screens/Settings';
import Search from '@/screens/Search';
import UserProfiles from '@/screens/client-profiles/UserProfiles';
import BusinessProfiles from '@/screens/client-profiles/BusinessProfiles';
import BusinessClientProfileDetail from '@/screens/client-profiles/BusinessProfiles/detail';
import UserClientProfileDetail from '@/screens/client-profiles/UserProfiles/detail';

interface IRoute {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: IRoute) => {
  const isAuthenticated = useAtomValue(isAuthenticatedState),
    location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace state={{ from: location }} />;
  }
  return <>{children}</>;
};

const PublicRoute = ({ children }: IRoute) => {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  return isAuthenticated ? <Navigate to="/" /> : <>{children}</>;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route index element={<Navigate replace to="loan-applications" />} />
      <Route
        path="home"
        element={
          // <PrivateRoute>
          <Home />
          // </PrivateRoute>
        }
      />
      <Route
        path="loans"
        element={
          // <PrivateRoute>
          <LoanBook />
          // </PrivateRoute>
        }
      />
      <Route
        path="loan/:id"
        element={
          // <PrivateRoute>
          <LoanDetail />
          // </PrivateRoute>
        }
      />
      <Route
        path="users"
        element={
          // <PrivateRoute>
          <Users />
          // </PrivateRoute>
        }
      />
      <Route
        path="user-profiles"
        element={
          // <PrivateRoute>
          <UserProfiles />
          // </PrivateRoute>
        }
      />
      <Route
        path="business-profiles"
        element={
          // <PrivateRoute>
          <BusinessProfiles />
          // </PrivateRoute>
        }
      />
      <Route
        path="business-profile/:id"
        element={
          // <PrivateRoute>
          <BusinessClientProfileDetail />
          // </PrivateRoute>
        }
      />
      <Route
        path="user-profile/:id"
        element={
          // <PrivateRoute>
          <UserClientProfileDetail />
          // </PrivateRoute>
        }
      />
      <Route
        path="loan-applications"
        element={
          // <PrivateRoute>
          <LoanApplications />
          // </PrivateRoute>
        }
      />
      <Route
        path="loan-application/:id"
        element={
          // <PrivateRoute>
          <LoanApplicationEdit />
          // </PrivateRoute>
        }
      />
      <Route
        path="loan-products"
        element={
          // <PrivateRoute>
          <LoanProducts />
          // </PrivateRoute>
        }
      />
      <Route
        path="loan-product/:id"
        element={
          // <PrivateRoute>
          <LoanProductDetail />
          // </PrivateRoute>
        }
      />
      <Route
        path="calculator"
        element={
          // <PrivateRoute>
          <AdminLoanCalculator />
          // </PrivateRoute>
        }
      />
      <Route
        path="search/:text"
        element={
          // <PrivateRoute>
          <Search />
          // </PrivateRoute>
        }
      />
      <Route
        path="settings"
        element={
          // <PrivateRoute>
          <AdminSettings />
          // </PrivateRoute>
        }
      />
      <Route
        path="instalments"
        element={
          // <PrivateRoute>
          <Instalments />
          // </PrivateRoute>
        }
      />
      <Route
        path="/signin"
        element={
          // <PublicRoute>
          <SignIn />
          // </PublicRoute>
        }
      />
      <Route
        path="signup"
        element={
          // <PublicRoute>
          <SignUp />
          // </PublicRoute>
        }
      />
      <Route
        path="verify"
        element={
          // <PublicRoute>
          <Verify />
          // </PublicRoute>
        }
      />
      <Route
        path="resetPassword"
        element={
          // <PublicRoute>
          <ForgotPasswordTokenConfirm />
          // </PublicRoute>
        }
      />
      <Route
        path="forgotPassword"
        element={
          // <PublicRoute>
          <ForgotPassword />
          // </PublicRoute>
        }
      />
      <Route
        path="newPasswordChallenge"
        element={
          // <PublicRoute>
          <NewPasswordChallenge />
          // </PublicRoute>
        }
      />
    </Route>,
  ),
) as any;
