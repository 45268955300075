import { Column, LoanApplicationCardType } from '@/components/LoanApplicationBoard/types';
import useStyles from './styles';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { useMemo } from 'react';
import { LoanApplicationCard } from '../LoanApplicationCard';

interface IColumnContainerProps {
  column: Column;
  loanCards: LoanApplicationCardType[];
}

export const ColumnContainer = (props: IColumnContainerProps) => {
  const { column, loanCards } = props;
  const classes = useStyles();
  const loanCardIds = useMemo(() => loanCards.map((loanCard) => loanCard.id), [loanCards]);

  const { setNodeRef } = useSortable({
    id: column.id,
    data: {
      type: 'Column',
      column,
    },
  });

  return (
    <div ref={setNodeRef} className={classes.columnContainer}>
      <div className={classes.cardHeader}>{column.title}</div>
      <div className={classes.cardContent}>
        {
          <SortableContext items={loanCardIds}>
            {loanCards?.map((lc) => {
              return (
                <div key={lc.id} className={classes.loanApplicationCard}>
                  <LoanApplicationCard loanCard={lc} />
                </div>
              );
            })}
          </SortableContext>
        }
      </div>
    </div>
  );
};
