import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { common, queryClient } from '@nayla/common';
import { ThemeProvider } from '@stryberventures/gaia-react.theme';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { RouterProvider } from 'react-router-dom';
import { router } from '@/router';
import { Provider } from 'jotai/react';
import { SnackbarProvider } from 'notistack';
import { SnackbarContent } from '@stryberventures/gaia-react.snackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import env from '@/env';
import theme from '@nayla/ui/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { QueryLoader } from '@nayla/ui';

// Set up the common configuration with API URLs
// apiUrl: The main API endpoint
// userMgmtUrl: The user management API endpoint
// The third parameter is left empty (it might be for a future use case)
common.setConfig(env.apiUrl, env.userMgmtUrl, '');

Sentry.init({
  dsn: 'https://5917728c2f09488ec1c989dbbde68de6@o4507590863486976.ingest.de.sentry.io/4507708134785104',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.naylafinance\.com/, /^https:\/\/api\.dev\.naylafinance\.com/],
  profilesSampleRate: 1.0, // Capture 100% of the profiles
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const App = () => {
  useEffect(() => {
    if (import.meta.env?.VITE_APP_TITLE) {
      document.title = import.meta.env.VITE_APP_TITLE;
    }
  }, []);

  return (
    <>
      <QueryLoader />
      <SnackbarProvider
        Components={{
          default: SnackbarContent,
          success: SnackbarContent,
          error: SnackbarContent,
          info: SnackbarContent,
          warning: SnackbarContent,
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <RouterProvider router={router} />
    </>
  );
};
const domNode = document.getElementById('root');

ReactDOM.createRoot(domNode as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App />
          </LocalizationProvider>
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
