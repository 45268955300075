import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    borderRadius: 12,
    background: theme.colors.background.white,
    padding: `${toRem(0)} ${toRem(40)}`,
  },
  tabs: {
    display: 'flex',
    flexWrap: 'wrap !important',
    overflow: 'auto',
    padding: toRem(10),
  },
  content: {
    marginTop: toRem(30),
  },
});
