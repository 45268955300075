import { IFetchRequest, IRole } from '../../interfaces';
import { common } from '../index';

export const getRole = (id: string, { fetcher, options }: IFetchRequest): Promise<IRole> => {
  return fetcher.get(`${common.endpoints.path.ROLE_ENDPOINT}/${id}`, options);
};

export const getRoles = ({ fetcher, options }: IFetchRequest): Promise<IRole[]> => {
  return fetcher.get(common.endpoints.path.ROLE_ENDPOINT, options);
};

export const addRole = (role: IRole, { fetcher, options }: IFetchRequest): Promise<IRole> => {
  return fetcher.post(common.endpoints.path.ROLE_ENDPOINT, { body: JSON.stringify(role), ...options });
};

export const updateRole = (id: string, role: IRole, { fetcher, options }: IFetchRequest): Promise<IRole> => {
  return fetcher.patch(`${common.endpoints.path.ROLE_ENDPOINT}/${id}`, { body: JSON.stringify(role), ...options });
};

export const deleteRole = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.ROLE_ENDPOINT}/${id}`, options);
};
