import React, { useState, useEffect } from 'react';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import InputPassword from '@stryberventures/gaia-react.input-password';
import Button from '@stryberventures/gaia-react.button';
import Text from '@/components/Text';
import * as yup from 'yup';
import useStyles from '../Login/styles';
import useSignUpStyles from './styles';
import { useNavigate } from 'react-router-dom';
import adminVocab from '@/vocabulary';
import { enqueueSnackbar } from 'notistack';
import { useSignUpMutation, useVerifyMutation } from '@nayla/common';

const validationSchema = yup.object().shape({
  firstName: yup.string().required(adminVocab.errors.required(adminVocab.users.properties.firstName)),
  lastName: yup.string().required(adminVocab.errors.required(adminVocab.users.properties.lastName)),
  email: yup.string().email().required(adminVocab.errors.required(adminVocab.users.properties.email)),
  password: yup
    .string()
    .min(8, adminVocab.authentication.validationMessage)
    .required(adminVocab.errors.required(adminVocab.users.properties.password)),
  repeatPassword: yup.string().oneOf([yup.ref('password')], adminVocab.authentication.passwordsDoNotMatch),
});

const SignUpComponent = () => {
  const classes = useStyles();
  const signUpClasses = useSignUpStyles();
  const [disabled, setDisabled] = React.useState(true);
  const navigate = useNavigate();
  const [showSignUpForm, setShowSignUpForm] = useState(true);
  const [userEmail, setUserEmail] = useState<string>('');
  const {
    mutate: signUp,
    isLoading: isSignUpLoading,
    data: response,
  } = useSignUpMutation({
    onError: () => {
      enqueueSnackbar(adminVocab.errors.signUpError, { variant: 'error' });
    },
  });
  const { mutate: verifyCode, isLoading: isVerificationLoading } = useVerifyMutation({
    onSuccess: () => {
      enqueueSnackbar(adminVocab.toasts.success.verified, { variant: 'success' });
      navigate('/signin');
    },
    onError: () => {
      enqueueSnackbar(adminVocab.errors.verificationError, { variant: 'error' });
    },
  });

  useEffect(() => {
    if (response) {
      if (response.mail_verification_type === 'code') {
        setShowSignUpForm(false);
      } else if (response.mail_verification_type === 'link') {
        enqueueSnackbar(adminVocab.toasts.success.verificationEmailSent, { variant: 'success' });
        navigate('/signin');
      } else {
        enqueueSnackbar(adminVocab.errors.oopsSomethingWentWrong, { variant: 'error' });
      }
    }
  }, [response, navigate]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}></div>
      <div className={classes.screen}>
        {showSignUpForm ? (
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={signUpClasses.title}>
              {adminVocab.authentication.signUp}
            </Text>
            <Form
              className={classes.form}
              validationSchema={validationSchema}
              onChange={(_, { isValid }) => setDisabled(!isValid)}
              onSubmit={async (data) => {
                const { email, password, firstName, lastName } = data;
                setUserEmail(email);
                // TODO: pass phone number correctly (back-end does not expect it as part of customAttributes, but rather a part of credentials)
                await signUp({ username: email, password, firstName, lastName }, {});
              }}
            >
              <Input
                height={56}
                name="firstName"
                variant="floatingLabel"
                fullWidth
                autoComplete="given-name"
                label={adminVocab.users.properties.firstName}
                placeholder={adminVocab.placeholders.firstName}
              />
              <Input
                height={56}
                name="lastName"
                variant="floatingLabel"
                fullWidth
                autoComplete="family-name"
                label={adminVocab.users.properties.lastName}
                placeholder={adminVocab.placeholders.lastName}
              />
              <Input
                height={56}
                name="email"
                variant="floatingLabel"
                fullWidth
                label={adminVocab.users.properties.email}
                placeholder={adminVocab.placeholders.email}
              />
              <InputPassword
                height={56}
                name="password"
                autoComplete="new-password"
                variant="floatingLabel"
                hint={adminVocab.authentication.passwordHint}
                fullWidth
                label={adminVocab.placeholders.password}
                placeholder={adminVocab.users.typePassword}
              />
              <InputPassword
                height={56}
                name="repeatPassword"
                autoComplete="new-password"
                variant="floatingLabel"
                fullWidth
                label={adminVocab.authentication.repeatPassword}
                placeholder={adminVocab.users.typePassword}
              />
              <Button type="submit" disabled={disabled} fullWidth shape="round" className={signUpClasses.button}>
                {isSignUpLoading ? adminVocab.loading : adminVocab.continue}
              </Button>
            </Form>
          </div>
        ) : (
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={classes.title}>
              {adminVocab.authentication.code}
            </Text>
            <Form
              className={classes.form}
              validationSchema={yup.object({
                code: yup.number().typeError(adminVocab.errors.mustBeNumber).required(),
              })}
              onSubmit={(data: any) =>
                verifyCode({
                  username: userEmail,
                  verificationCode: data.code,
                })
              }
            >
              <Input
                height={56}
                variant="floatingLabel"
                name="code"
                fullWidth
                autoComplete="off"
                label={adminVocab.authentication.code}
                placeholder={adminVocab.placeholders.code}
                type="number"
              />
              <Button type="submit" fullWidth shape="round" className={classes.button}>
                {isVerificationLoading ? adminVocab.loading : adminVocab.submit}
              </Button>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpComponent;
