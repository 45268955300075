import { useFetchWrapper } from '../../hooks';
import { useQuery } from '@tanstack/react-query';
import { getInstalment, getInstalments } from '../requests';

export const instalmentQueryKeys = {
  instalments: (query?: string) => (query ? ['instalments', query] : ['instalments']),
  instalment: (id: string | undefined, expand: string[]) => {
    const expandString = expand.sort().join(',');
    return ['instalments', id, expandString];
  },
};

export const useInstalmentsQuery = (query?: string) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    instalmentQueryKeys.instalments(query),
    ({ signal }) => getInstalments({ fetcher, options: { signal }, query }),
    {
      initialData: [],
      onError: () => {
        //enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanApplication.title), { variant: 'error' });
      },
    },
  );
};

export const useInstalmentQuery = (id: string | undefined, expand: string[] = []) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    instalmentQueryKeys.instalment(id, expand),
    ({ signal }) => getInstalment(id, { fetcher, options: { signal }, expand }),
    {
      initialData: null,
      enabled: !!id,
    },
  );
};
