import React from 'react';

interface ViewportProps {
  width: number;
  height: number;
}

export const SCREEN_SIZE = {
  small: 576,
  medium: 768,
  large: 992,
  xLarge: 1200,
  xxLarge: 1400,
};

export const MEDIA_QUERIES = {
  mobile: `@media (min-width: ${SCREEN_SIZE.small}px)`,
  tablet: `@media (min-width: ${SCREEN_SIZE.medium}px)`,
  laptop: `@media (min-width: ${SCREEN_SIZE.large}px)`,
  desktop: `@media (min-width: ${SCREEN_SIZE.xLarge}px)`,
  largeDesktop: `@media (min-width: ${SCREEN_SIZE.xxLarge}px)`,
};

const viewportContext = React.createContext({} as ViewportProps);

export const ViewportProvider = ({ children }: any) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <viewportContext.Provider value={{ width, height }}>{children}</viewportContext.Provider>;
};

export const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};
