import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import adminVocab from '@/vocabulary';
import { FC, useState } from 'react';
import useStyles from './styles';
import Tabs from '@stryberventures/gaia-react.tabs';
import ApplicationTab from '@/screens/LoanApplications/detail/tabs/submission/application';
import DocumentsTab from '@/screens/LoanApplications/detail/tabs/submission/documents';
import OpenBankingTab from '@/screens/LoanApplications/detail/tabs/submission/open-banking';

const TAB_IDS = {
  APPLICATION: 'application',
  DOCUMENTS: 'documents',
  OPEN_BANKING: 'openBanking',
  KYC: 'kyc',
  KYB: 'kyb',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.APPLICATION,
    label: adminVocab.loanApplication.tabs.application,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.DOCUMENTS,
    label: adminVocab.loanApplication.tabs.documents,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.OPEN_BANKING,
    label: adminVocab.loanApplication.tabs.openBanking,
  },
  {
    active: false,
    disabled: true,
    id: TAB_IDS.KYC,
    label: adminVocab.loanApplication.tabs.kyc,
  },
  {
    active: false,
    disabled: true,
    id: TAB_IDS.KYB,
    label: adminVocab.loanApplication.tabs.kyb,
  },
];

const SubmissionTab: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TAB_IDS.APPLICATION);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>
        {activeTab === TAB_IDS.APPLICATION && <ApplicationTab />}
        {activeTab === TAB_IDS.DOCUMENTS && <DocumentsTab />}
        {activeTab === TAB_IDS.OPEN_BANKING && <OpenBankingTab />}
      </div>
    </div>
  );
};

export default SubmissionTab;
