export default {
  apiUrl: 'https://api-stage.meissa.stryber.io',
  userMgmtUrl: 'https://auth-stage.meissa.stryber.io/v1',
  authUrl: 'https://auth.dev.naylafinance.com/',
  cognitoGoogleSignIn: {
    domain: 'https://idcs-507b6f07d90a465ea8df62ddebbed831.identity.oraclecloud.com',
    clientId: 'bccf2eb50a4f4ffbbaf208d3b95ec85b',
    responseType: 'code',
    redirectUri: 'https://meissa-admin-stage.vercel.app/signin',
    state: 'state',
    scopes: ['openid', 'email', 'urn:opc:idm:t.user.me'],
    identityProvider: 'Google',
  },
};
