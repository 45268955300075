import { IAMLData, ILoanApplication } from './loan-application';
import { ILoan } from './loan';
import { IUser } from './user';
import { IContract } from './contract';
import { EmployeeCountOptions, YearlyRevenue } from '../enums';

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export enum AMLStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

export interface IScreenLoanResponse {
  id: string;
  amlStatus: AMLStatus;
  url: string;
}

interface IAddress {
  id: string;
  street: string;
  city: string;
  country: string;
  zipCode?: string;
  buildingNumber?: string;
  additionalNumber?: string;
  unitNumber?: string;
  districtName?: string;
  telephone1?: string;
  telephone2?: string;
  fax1?: string;
  postalBox1?: string;
  postalBox2?: string;
  email1?: string;
  generalAddress?: string;
  state?: string;
  business: IBusiness;
  user: IUser;
}

export interface IISICActivity {
  id: string;
  name: string;
  nameEn: string;
}

export interface IBusinessLocation {
  id: string;
  name: string;
}

interface IBusinessCapital {
  announcedAmount?: number;
  paidAmount?: number;
  share?: { sharePrice: number; sharesCount: number };
  subscribedAmount?: number;
}

interface IBusinessFiscalYear {
  day: number;
  month: number;
  calendarType: {
    id: string;
    name: string;
  };
}

interface IBusinessParty {
  name?: string;
  birthDate?: string;
  identity?: { id: string; type: string };
  nationality?: { id: string; name: string };
  relation?: { id: string; name: string };
}

interface IBusinessType {
  id: string;
  name: string;
}

export interface IKYBData {
  businessActivity?: any;
  businessAddress?: string;
  businessName?: string;
  data?: {
    activities?: {
      description?: string;
      isic?: IISICActivity[];
    };
    address?: {
      general: IAddress;
      national: IAddress;
    };
    businessType?: IBusinessType;
    cancellation?: any;
    capital?: IBusinessCapital;
    company?: any;
    crEntityNumber?: number;
    crMainEntityNumber?: number;
    crMainNumber?: number;
    fiscalYear?: IBusinessFiscalYear;
    location?: IBusinessLocation;
    parties?: IBusinessParty[];
    status?: IISICActivity;
    urls?: string[];
  };
  expiryDate?: string;
  issueDate?: string;
  status?: boolean;
}

export interface IBusiness {
  id: string;
  externalId: string;
  companyName: string;
  commercialRegistrationNumber: string;
  registrationDate: string;
  operationalSince: string;
  activity: string;
  ownershipStructure: string;
  website: string;
  email: string;
  phone: string;
  obConnected: boolean;
  blockedUntil?: string;
  permanentlyBlocked: boolean;
  kybVerificationStatus?: any;
  kybRejectionReason?: string;
  kybRunAt?: string;
  kybExpiryDate?: string;
  amlData?: IAMLData;
  amlStatus?: AMLStatus;
  hyperPayRegistrationId?: string;
  hyperPayCardholderInitiatedTransactionId?: string;
  paymentBrand?: string;
  addresses?: IAddress[];
  loanApplications: ILoanApplication[];
  loans: ILoan[];
  contracts: IContract[];
  kybData?: IKYBData;
  crEntityNumber?: string;
  crMainNumber?: string;
  crMainEntityNumber?: string;
  cancellation?: string;
  company?: string;
  urls?: string[];
  parties?: IBusinessParty[];
  fiscalYear?: IBusinessFiscalYear;
  status?: IISICActivity;
  capital?: IBusinessCapital;
  industry?: string;
  industries?: any;
  location?: IBusinessLocation;
  locationId?: string;
  yearlyRevenue?: YearlyRevenue;
  annualSalesGrowth?: string;
  employeeCount: EmployeeCountOptions;
  businessType?: IBusinessType;
  owner: IUser;
  createdAt: string;
  updatedAt: string;
}

export interface IBusinessSnapshot {
  yearlyRevenue: YearlyRevenue;
  bank: string;
  bic: string;
  companyName: string;
  createdAt: string;
  iban: string;
  id: string;
  industry: string;
  locationName: string;
  registrationDate: string;
  employeeCount: string;
  updatedAt: string;
}
