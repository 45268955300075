import { downloadCSV, getInstalmentsCSV, getLoansCSV, useFetchWrapper } from '@nayla/common';
import Button from '@stryberventures/gaia-react.button';
import useStyles from './styles';
import adminVocab from '@/vocabulary';

interface IDownloadCSVProps {
  downloadFn: typeof getLoansCSV | typeof getInstalmentsCSV;
  fileName: string;
}

export const DownloadCSV = ({ downloadFn, fileName }: IDownloadCSVProps) => {
  const classes = useStyles();
  const fetcher = useFetchWrapper();

  return (
    <Button
      className={classes.btn}
      variant="ghost"
      onClick={async () => {
        const csvData = await downloadFn({ fetcher });
        downloadCSV(csvData, `${fileName}.csv`);
      }}
    >
      {adminVocab.downloadCSVFile}
    </Button>
  );
};
