import * as React from 'react';
import Text from '@/components/Text';
import adminVocab from '@/vocabulary';
import { UserForm } from '@/components/UserForm';
import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableSection,
} from '@stryberventures/gaia-react.table';
import { IOption } from '@stryberventures/gaia-react.combobox';
import Button from '@stryberventures/gaia-react.button';
import { CheckIcon, CloseCircleIcon, CountIcon, DeleteIcon, EditIcon } from '@stryberventures/gaia-react.icons';
import Chip from '@stryberventures/gaia-react.chip';
import { enqueueSnackbar } from 'notistack';
import {
  getComparator,
  IUser,
  Order,
  queryClient,
  stableSort,
  useDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
} from '@nayla/common';
import theme from '@nayla/ui/styles';
import { EnhancedTableHead, IHeadCell } from '@nayla/ui/components/TableHead';

interface IUsersTableProps {
  rows: IUser[];
}

const headCells: readonly IHeadCell[] = [
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: adminVocab.users.properties.username,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: adminVocab.users.properties.email,
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: adminVocab.users.properties.phone,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: adminVocab.users.properties.enabled,
  },
];

export const UsersTable = ({ rows }: IUsersTableProps) => {
  const classes = useStyles(),
    [order, setOrder] = React.useState<Order>('asc'),
    [orderBy, setOrderBy] = React.useState<keyof IUser>('username'),
    [selected, setSelected] = React.useState<readonly string[]>([]),
    [page, setPage] = React.useState(0),
    [rowsPerPage, setRowsPerPage] = React.useState(10),
    { mutate: deleteUser } = useDeleteUserMutation({
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
        setSelected([]);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.deleted(adminVocab.users.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.delete(adminVocab.users.entityName), { variant: 'error' });
      },
    }),
    { mutate: enableUser } = useEnableUserMutation({
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
        setSelected([]);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.enabled(adminVocab.users.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.enable(adminVocab.users.entityName), { variant: 'error' });
      },
    }),
    { mutate: disableUser } = useDisableUserMutation({
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
        setSelected([]);
      },
      onSuccess: () => {
        enqueueSnackbar(adminVocab.toasts.success.disabled(adminVocab.users.entityName), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(adminVocab.toasts.errors.disable(adminVocab.users.entityName), { variant: 'error' });
      },
    }),
    handleRequestSort = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, property: keyof IUser) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      },
      [order, orderBy],
    ),
    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = React.useCallback((option: IOption) => {
      const updatedRowsPerPage = parseInt(option.label, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
    }, []),
    isSelected = (name: string) => selected.indexOf(name) !== -1,
    // Avoid a layout jump when reaching the last page with empty rows.
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0,
    [userModalShown, setUserModalShown] = React.useState(false),
    [deleteModalShown, setDeleteModalShown] = React.useState(false),
    rowActions = [
      {
        icon: <EditIcon fill={theme.colors.text.secondary} />,
        label: adminVocab.table.actions.edit,
        actionFcn: (id: string) => {
          setSelected([id]);
          setUserModalShown(true);
        },
      },
      {
        id: 'enable',
        icon: <CheckIcon variant="default" fill={theme.colors.text.secondary} />,
        label: adminVocab.table.actions.enable,
        actionFcn: (id: string) => enableUser(id),
      },
      {
        id: 'disable',
        icon: <CloseCircleIcon variant="default" fill={theme.colors.text.secondary} />,
        label: adminVocab.table.actions.disable,
        actionFcn: (id: string) => disableUser(id),
      },
      {
        icon: <DeleteIcon fill={theme.colors.text.secondary} />,
        label: adminVocab.table.actions.delete,
        actionFcn: (id: string) => {
          setSelected([id]);
          setDeleteModalShown(true);
        },
      },
    ];

  return (
    <>
      <div className={classes.header}>
        <Button
          className={classes.createItem}
          onClick={() => {
            setSelected([]);
            setUserModalShown(true);
          }}
          iconLeft={() => <CountIcon fill={theme.colors.primary.main500} variant="plus" />}
          type="button"
          variant="outlined"
          shape="round"
        >
          {adminVocab.table.actions.add}
        </Button>
      </div>
      <TableContainer>
        {/* <TableSection> */}
        {/*   <Text variant="body1"> {vocab.users.tableTitle}</Text> */}
        {/* </TableSection> */}
        {!!selected.length && (
          <TableSection>
            <Text variant="components1">{selected.length} Items Selected</Text>
          </TableSection>
        )}
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headcells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id),
                  labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={classes.tableRow}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell id={labelId}>{row.username}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      <div>
                        {row.enabled ? (
                          <Chip className={classes.activeChip}>{adminVocab.users.enabled.true}</Chip>
                        ) : (
                          <Chip className={classes.failureChip}>{adminVocab.users.enabled.false}</Chip>
                        )}
                        <div className={classes.actionsContainer}>
                          {rowActions
                            .filter((action) => (row.enabled ? action.id !== 'enable' : action.id !== 'disable'))
                            .map((action) => {
                              return (
                                <div
                                  className={classes.actionIcon}
                                  key={action.label}
                                  onClick={() => action.actionFcn(row.id)}
                                >
                                  {action.icon}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {userModalShown && (
        <UserForm selectedUsername={selected?.[0] ?? ''} open={userModalShown} setOpen={setUserModalShown} />
      )}
      {deleteModalShown && (
        <ModalDialog
          title={adminVocab.users.deleteUserTitle}
          description={adminVocab.users.deleteUserDescription}
          onSubmit={() => {
            const selectedIndex = selected[0];
            if (selectedIndex) {
              deleteUser(selectedIndex);
            }
            setDeleteModalShown(false);
          }}
          open={deleteModalShown}
          setOpen={setDeleteModalShown}
        />
      )}
    </>
  );
};
