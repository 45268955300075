import { useRef, useState } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import Form from '@stryberventures/gaia-react.form';
import adminVocab from '@/vocabulary';
import { DatePicker } from '@mui/x-date-pickers';
import { IOption } from '@nayla/ui/components/Select';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { Dayjs } from 'dayjs';
import { ILoansFilters } from '@/components/LoanBookTable';
import { LoanState } from '@nayla/common';

export interface ILoansFiltersProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  title: string;
  filters: ILoansFilters;
  setFilters: (filters: ILoansFilters) => void;
}

const stateOptions: IOption[] = Object.entries(LoanState).map(([value]) => ({
  label: adminVocab.loans.states[value as LoanState],
  value: value as LoanState,
}));

export const LoansFilters = ({ open, setOpen, title, filters, setFilters }: ILoansFiltersProps) => {
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(filters.from);
  const [dateTo, setDateTo] = useState<Dayjs | null>(filters.to);
  const [state, setState] = useState(filters.state);
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles(),
    handleFormSubmit = () => {
      formRef.current?.submit();
      if (formRef?.current?.isValid) {
        setOpen(false);
        return;
      }
    };
  return (
    <ModalDialog setOpen={setOpen} open={open} title={title} onSubmit={handleFormSubmit} submitText="Apply">
      <Form
        ref={formRef}
        onSubmit={() => {
          setFilters({
            from: dateFrom,
            to: dateTo,
            state,
          });
        }}
      >
        <div className={classes.container}>
          <div className={classes.filterCaption}>{adminVocab.loans.filters.startDate}</div>
          <div className={classes.datePickers}>
            <div>
              <DatePicker
                label={adminVocab.loans.filters.from}
                onChange={(date) => setDateFrom(date)}
                value={dateFrom}
              />
            </div>
            <div>
              <DatePicker label={adminVocab.loans.filters.to} onChange={(date) => setDateTo(date)} value={dateTo} />
            </div>
          </div>
          <div className={classes.filterCaption}>{adminVocab.loans.filters.status}</div>
          <Select
            label={adminVocab.loans.properties.state}
            onChange={(event: SelectChangeEvent) => {
              setState(event.target.value as LoanState);
            }}
          >
            {stateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Form>
    </ModalDialog>
  );
};
