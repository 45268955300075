import { FC, useState } from 'react';
import useStyles from '@/components/Login/styles';
import useSignInStyles from '../SignIn/styles';
import adminVocab from '@/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import InputPassword from '@stryberventures/gaia-react.input-password';
import Button from '@stryberventures/gaia-react.button';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Text from '@/components/Text';
import { enqueueSnackbar } from 'notistack';
import {
  IAuthForgotPasswordResponse,
  passwordRegExp,
  useForgotPasswordConfirmMutation,
  useForgotPasswordMutation,
} from '@nayla/common';

const ForgotPassword: FC = () => {
  const classes = useStyles(),
    signInClasses = useSignInStyles(),
    [codeReceived, setCodeReceived] = useState(false),
    navigate = useNavigate(),
    onSuccess = (response: IAuthForgotPasswordResponse) => {
      if (response.forgot_password_type === 'code') {
        setCodeReceived(true);
        enqueueSnackbar(adminVocab.authentication.codeSent, { variant: 'success' });
      } else if (response.forgot_password_type === 'link') {
        enqueueSnackbar(adminVocab.toasts.success.verificationEmailSent, { variant: 'success' });
        navigate('/');
      }
    },
    onChangeSuccess = () => {
      enqueueSnackbar(adminVocab.authentication.passwordChanged, { variant: 'success' });
      navigate('/');
    },
    onError = () => {
      enqueueSnackbar(adminVocab.errors.oops, { variant: 'error' });
    },
    { mutate: forgotPassword, isLoading: isForgotPasswordLoading } = useForgotPasswordMutation(onSuccess, onError),
    { mutate: forgotPasswordConfirm, isLoading: isForgotPasswordConfirmLoading } = useForgotPasswordConfirmMutation({
      onSuccess: () => onChangeSuccess(),
      onError,
    }),
    handleSubmit = (values: any) => {
      forgotPassword(values);
    },
    handleSubmitChange = (values: any) => {
      forgotPasswordConfirm(values);
    };
  return (
    <div className={signInClasses.container}>
      <div className={classes.wrapper}>
        <div className={classes.logoWrapper}></div>
        <div className={classes.screen}>
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={classes.title}>
              {adminVocab.authentication.forgotPassword}
            </Text>
            {!codeReceived ? (
              <Form
                className={classes.form}
                onSubmit={(data: any) => handleSubmit(data)}
                validationSchema={yup.object({
                  username: yup.string().required(), // email()
                })}
                key={'main-form'}
              >
                <Input
                  name="username"
                  fullWidth
                  label="Email"
                  variant="floatingLabel"
                  placeholder={adminVocab.placeholders.email}
                  height={56}
                />
                <div className={signInClasses.buttonContainer}>
                  <Button className={classes.button} type="submit">
                    {isForgotPasswordLoading ? adminVocab.loading : adminVocab.submit}
                  </Button>
                </div>
              </Form>
            ) : (
              <Form
                className={classes.form}
                onSubmit={(data: any) => handleSubmitChange(data)}
                validationSchema={yup.object({
                  username: yup.string().required(),
                  confirmationCode: yup.string().required(),
                  password: yup.string().required().matches(passwordRegExp, {
                    message: adminVocab.authentication.validationMessage,
                  }),
                  repeatPassword: yup
                    .string()
                    .nullable()
                    .oneOf([yup.ref('password'), null], adminVocab.authentication.passwordsDoNotMatch),
                })}
              >
                <Input
                  name="username"
                  fullWidth
                  label="Email"
                  variant="floatingLabel"
                  placeholder={adminVocab.placeholders.email}
                  height={56}
                />
                <Input
                  height={56}
                  variant="floatingLabel"
                  name="confirmationCode"
                  fullWidth
                  autoComplete="off"
                  label={adminVocab.authentication.code}
                  placeholder={adminVocab.placeholders.code}
                  type="number"
                />
                <InputPassword
                  height={56}
                  name="password"
                  autoComplete="new-password"
                  variant="floatingLabel"
                  hint={adminVocab.authentication.passwordHint}
                  fullWidth
                  label={adminVocab.placeholders.password}
                  placeholder={adminVocab.users.typePassword}
                />
                <InputPassword
                  height={56}
                  name="repeatPassword"
                  autoComplete="new-password"
                  variant="floatingLabel"
                  fullWidth
                  label={adminVocab.authentication.repeatPassword}
                  placeholder={adminVocab.users.typePassword}
                />
                <div className={signInClasses.buttonContainer}>
                  <Button className={classes.button} type="submit">
                    {isForgotPasswordConfirmLoading ? adminVocab.loading : adminVocab.submit}
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
