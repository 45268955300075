import withStyles from 'react-jss';
import styles from './styles';
import { Outlet } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from '../Header';
import { useAtomValue } from 'jotai';
import { isAuthenticatedState, useMeUserQuery } from '@nayla/common';
import env from '@/env';

const Root = (props: any) => {
  const { data, isLoading, error } = useMeUserQuery();
  const isAuthenticated = !!data;

  if (isLoading) {
    return null;
  }

  if (error) {
    console.error('[Root] Error: ', error);
    window.location.href = `${env.authUrl}/login`;
  }

  // const isAuthenticated = useAtomValue(isAuthenticatedState);
  return (
    <div className={props.classes.root}>
      {!isAuthenticated && <Header />}
      {isAuthenticated && <SideBar />}
      <Outlet />
    </div>
  );
};

export default withStyles(styles)(Root);
