import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& form': {
      gap: 10,
      flexDirection: 'column',
      display: 'flex',
    },
    width: '100%',
  },
  buttonContainer: {
    flexDirection: 'row-reverse',
    gap: 15,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },

  submit: {
    height: 10,
  },
});
