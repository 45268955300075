import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {},
  tabs: {
    display: 'flex',
    flexWrap: 'wrap !important',
    overflow: 'auto',
  },
  content: {
    marginTop: toRem(30),
  },
});
