import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  loanCard: {
    display: 'flex',
    padding: `${toRem(22)} ${toRem(24)}`,
    alignItems: 'center',
    cursor: 'pointer',
    gap: toRem(12),
    overflow: 'hidden',
    justifyContent: 'flex-start',
    height: toRem(68),
    boxSizing: 'border-box',
    userSelect: 'none',
  },
  loanCardDragging: {
    border: `0.5px solid ${theme.colors.neutralGray.light200}`,
    opacity: 0.5,
  },
  loanId: {
    fontWeight: 400,
    color: theme.colors.neutralGray.extraDark900,
    fontSize: toRem(14),
  },
  companyName: {
    fontSize: toRem(14),
    fontWeight: 400,
    color: theme.colors.neutralGray.extraDark900,
    whiteSpace: 'nowrap',
  },
  loanInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: toRem(8),
    color: theme.colors.neutralGray.dark600,
    fontSize: toRem(14),
    fontWeight: 400,
  },
  tag: {
    maxHeight: toRem(24),
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > span': {
      fontSize: toRem(12),
    },
  },
});
