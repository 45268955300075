import { FC } from 'react';
import { InstalmentsTable } from '@/components/InstalmentsTable';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { DownloadCSV } from '@/components/DownloadCSV';
import { getInstalmentsCSV } from '@nayla/common';

const Instalments: FC = () => {
  return (
    <Layout title={adminVocab.instalments.tableTitle} subtitle={adminVocab.instalments.tableDescription}>
      <DownloadCSV downloadFn={getInstalmentsCSV} fileName="instalments" />
      <InstalmentsTable />
    </Layout>
  );
};

export default Instalments;
