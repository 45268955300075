import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: toRem(12),
    padding: `${toRem(24)} ${toRem(24)} ${toRem(30)} ${toRem(30)}`,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.background.white,
    height: toRem(252),
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: toRem(34),
    lineHeight: toRem(40),
    fontWeight: 500,
    color: theme.colors.contrast.black,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: toRem(110),
  },
  mainFigure: {
    color: theme.colors.contrast.black,

    fontWeight: 600,
    lineHeight: toRem(28),
    [MEDIA_QUERIES.laptop]: {
      fontSize: toRem(40),
    },
  },
  editButton: {
    padding: 0,
  },
});
