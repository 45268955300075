import {
  contractQueryKeys,
  formatDate,
  getTagColorByOfferState,
  IOffer,
  numberWithCommas,
  queryClient,
  useUploadContractMutation,
} from '@nayla/common';
import useStyles from './styles';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import dayjs from 'dayjs';
import Tag from '@stryberventures/gaia-react.tag';
import adminVocab from '@/vocabulary';
import Button from '@stryberventures/gaia-react.button';
import { DocumentIcon } from '@stryberventures/gaia-react.icons';
import theme from '@nayla/ui/styles';
import { enqueueSnackbar } from 'notistack';

const headCells = [
  { id: 'date', label: 'Date Provided' },
  { id: 'amount', label: 'Amount' },
  { id: 'terms', label: 'Terms' },
  { id: 'monthlyProfitRate', label: 'Monthly Profit Rate' },
  { id: 'adminFees', label: 'Admin Fees' },
  { id: 'status', label: 'Status' },
];

interface IOffersTableProps {
  offers: IOffer[];
  loanApplicationId?: string;
  amlPassed?: boolean;
}

export const OffersTable = ({ offers = [], loanApplicationId, amlPassed }: IOffersTableProps) => {
  const classes = useStyles();
  const { mutate: uploadContract } = useUploadContractMutation({
    onSettled: () => {
      queryClient.invalidateQueries(contractQueryKeys.contractsPerLoanApplication(loanApplicationId, ['offer']));
    },
    onSuccess: () => {
      enqueueSnackbar('Contract uploaded successfully', { variant: 'success' });
    },
    onError: (errorMessage) => {
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  if (!offers.length) {
    return (
      <div className={classes.noOffers}>
        <p>{adminVocab.loanApplication.offer.noOffers}</p>
      </div>
    );
  }

  return (
    <>
      <div className={classes.container}>
        <p>{adminVocab.loanApplication.offer.runAMLMessage}</p>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[...headCells, ...(amlPassed && loanApplicationId ? [{ id: 'contract', label: 'Contract' }] : [])].map(
                (headCell) => (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {offers
              .sort((a: IOffer, b: IOffer) => dayjs(a?.createdAt).valueOf() - dayjs(b?.createdAt).valueOf())
              .map((offer: IOffer, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{formatDate(offer?.createdAt)}</TableCell>
                    <TableCell>{numberWithCommas(offer?.amount)}</TableCell>
                    <TableCell>{offer?.terms}</TableCell>
                    <TableCell>{offer?.monthlyProfitRate}</TableCell>
                    <TableCell>{offer?.adminFees}</TableCell>
                    <TableCell>
                      {' '}
                      {offer.state ? (
                        <Tag color={getTagColorByOfferState(offer.state)}>
                          {adminVocab.loanApplication.offer.states[offer.state]}
                        </Tag>
                      ) : (
                        adminVocab.na
                      )}
                    </TableCell>
                    {amlPassed && loanApplicationId && (
                      <TableCell>
                        <Button
                          onClick={() =>
                            uploadContract({
                              offerId: offer.id,
                            })
                          }
                          iconRight={<DocumentIcon width={16} height={16} fill={theme.colors.primary.main500} />}
                          className={classes.btn}
                          variant="outlined"
                        >
                          {adminVocab.loanApplication.contract.sendNewContract}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OffersTable;
