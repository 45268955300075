import useStyles from './styles';
import adminVocab from '@/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import { useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { SearchDialog } from '../SearchDialog';
import SearchInput from '@stryberventures/gaia-react.search-input';
import { useNavigate } from 'react-router-dom';

interface IModal {
  setOpen: (state: boolean) => void;
}

const SearchModal = ({ setOpen }: IModal) => {
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    if (!formRef?.current?.isValid) {
      return;
    }
    formRef.current?.submit();
    setOpen(false);
  };

  return (
    <SearchDialog
      id="search-modal"
      title={'Global search'}
      open={true}
      setOpen={setOpen}
      onSubmit={handleFormSubmit}
      // submitText={}
      // cancelText={}
    >
      <Form
        ref={formRef}
        onSubmit={(data) => {
          console.log(data);
          navigate('search/' + data.searchText);
          setOpen(false);
        }}
      >
        <div className={classes.container}>
          <>
            <SearchInput
              fullWidth
              onBlur={() => {}}
              onFocus={function noRefCheck() {}}
              placeholder="Search"
              name="searchText"
              size="large"
              autoFocus
            />
            <div className={classes.description}>
              <div>{adminVocab.search.searchExampleTitle}</div>
              <div>{adminVocab.search.searchExample1}</div>
              <div>{adminVocab.search.searchExample2}</div>
              <div>{adminVocab.search.searchExample3}</div>
              <div>{adminVocab.search.searchExample4}</div>
              <div>{adminVocab.search.searchExample5}</div>
              <div>{adminVocab.search.searchExample6}</div>
            </div>
          </>
        </div>
      </Form>
    </SearchDialog>
  );
};

export default SearchModal;
