import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import {
  formatDate,
  IRiskAssessment,
  numberWithCommas,
  useLoanApplicationQuery,
  useRiskAssessmentsQuery,
} from '@nayla/common';
import { loan } from '@/store/loan';
import Tag from '@stryberventures/gaia-react.tag';
import { CREDIT_SCORE_OPTIONS } from '@nayla/ui/components/RiskAssessmentDropdown';
import { toRem } from '@nayla/ui';
import adminVocab from '@/vocabulary';

const ApplicationInfoTab: FC = () => {
  const classes = useStyles();
  const loanData = useAtomValue(loan);
  // const { data: locations } = useBusinessLocationsQuery();
  const { data: loanApplication } = useLoanApplicationQuery(loanData?.loan.loanApplication?.id, ['business', 'user']);
  const { data: riskAssessments } = useRiskAssessmentsQuery(loanApplication?.id);

  const fullName = loanApplication?.user?.firstName + ' ' + loanApplication?.user?.lastName;
  const profession = loanApplication?.user?.profession ?? adminVocab.na;
  const address = loanApplication?.user?.address ?? adminVocab.na;
  const education = loanApplication?.user?.education ?? adminVocab.na;

  const businessName = loanApplication?.business?.companyName ?? adminVocab.na;
  const dateFounded = formatDate(loanApplication?.business?.registrationDate?.toString()) ?? adminVocab.na;
  const industry = loanApplication?.business?.industry ?? adminVocab.na;
  const location = loanApplication?.businessSnapshot?.locationName;
  const averageYearlyRevenue = loanApplication?.business?.averageYearlyRevenue
    ? numberWithCommas(loanApplication?.business?.averageYearlyRevenue)
    : adminVocab.na;
  const annualSalesGrowth = loanApplication?.business?.annualSalesGrowth
    ? numberWithCommas(loanApplication?.business?.annualSalesGrowth)
    : adminVocab.na;
  const purpose = loanApplication?.purpose ?? adminVocab.na;

  const initialRiskAssessment: IRiskAssessment | null = (riskAssessments && riskAssessments[0]) ?? null;

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <p style={{ fontWeight: 600 }}>Personal Info</p>
        <p>Name: {fullName}</p>
        <p>Profession: {profession}</p>
        <p>Education Level: {education}</p>
        <p>Address: {address}</p>
      </div>
      <div className={classes.info}>
        <p style={{ fontWeight: 600 }}>Business Info</p>
        <p>Business Name: {businessName}</p>
        <p>Date Founded: {dateFounded}</p>
        <p>Industry: {industry}</p>
        <p>Location: {location}</p>
        <p>Average Year Revenue: {averageYearlyRevenue}</p>
        <p>Annual Sales Growth: {annualSalesGrowth}</p>
        <p>Reason for the loan: {purpose}</p>
      </div>
      <div className={classes.info}>
        <p style={{ fontWeight: 600 }}>Risk Score: N/A</p>
        {initialRiskAssessment ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: toRem(10) }}>
            <p style={{ fontWeight: 600 }}>Risk Category:</p>
            <Tag
              className={classes.tagCentered}
              color={
                CREDIT_SCORE_OPTIONS.find((score) => score.value === initialRiskAssessment?.riskCategory?.name)?.color
              }
            >
              {initialRiskAssessment ? initialRiskAssessment.riskCategory?.name : adminVocab.na}
            </Tag>
          </div>
        ) : null}
        <a href="#">Download Offer Detail</a>
      </div>
    </div>
  );
};

export default ApplicationInfoTab;
