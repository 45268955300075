import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import adminVocab from '@/vocabulary';
import useStyles from './styles';
import Tabs from '@stryberventures/gaia-react.tabs';
import CalculatorTab from '@/screens/LoanApplications/detail/tabs/contract-negotiation/calculator';
import OffersTab from '@/screens/LoanApplications/detail/tabs/contract-negotiation/offers';
import AmlTab from '@/screens/LoanApplications/detail/tabs/contract-negotiation/aml';
import PaymentTab from '@/screens/LoanApplications/detail/tabs/contract-negotiation/payment';
import ContractTab from '@/screens/LoanApplications/detail/tabs/contract-negotiation/contract';
import { FC, useState } from 'react';

export const CONTRACT_TAB_IDS = {
  CALCULATOR: 'calculator',
  OFFERS: 'offers',
  AML: 'aml',
  CONTRACT: 'contract',
  PAYMENT: 'payment',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: CONTRACT_TAB_IDS.CALCULATOR,
    label: adminVocab.loanApplication.tabs.calculator,
  },
  {
    active: false,
    disabled: false,
    id: CONTRACT_TAB_IDS.OFFERS,
    label: adminVocab.loanApplication.tabs.offers,
  },
  {
    active: false,
    disabled: false,
    id: CONTRACT_TAB_IDS.AML,
    label: adminVocab.loanApplication.tabs.aml,
  },
  {
    active: false,
    disabled: false,
    id: CONTRACT_TAB_IDS.CONTRACT,
    label: adminVocab.loanApplication.tabs.contract,
  },
  {
    active: false,
    disabled: false,
    id: CONTRACT_TAB_IDS.PAYMENT,
    label: adminVocab.loanApplication.tabs.payment,
  },
];

const ContractNegotiationTab: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(CONTRACT_TAB_IDS.CALCULATOR);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS} // Use TABS array for tab definitions
        variant="default"
      />
      {/* Render the content based on the active tab */}
      <div className={classes.content}>{activeTab === CONTRACT_TAB_IDS.CALCULATOR && <CalculatorTab />}</div>
      <div className={classes.content}>{activeTab === CONTRACT_TAB_IDS.OFFERS && <OffersTab />}</div>
      <div className={classes.content}>{activeTab === CONTRACT_TAB_IDS.AML && <AmlTab />}</div>
      <div className={classes.content}>{activeTab === CONTRACT_TAB_IDS.CONTRACT && <ContractTab />}</div>
      <div className={classes.content}>{activeTab === CONTRACT_TAB_IDS.PAYMENT && <PaymentTab />}</div>
    </div>
  );
};

export default ContractNegotiationTab;
