import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: toRem(20),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: toRem(12),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(14),
    },
  },
  rejectButton: {
    height: toRem(24),
    width: toRem(130),
    fontSize: toRem(10),
    ackgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
    },
    [MEDIA_QUERIES.desktop]: {
      width: toRem(194),
      fontSize: toRem(16),
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: toRem(20),
    marginTop: toRem(20),
  },
  actionButton: {
    width: '100%',
    maxWidth: toRem(200),
    height: toRem(40),
    fontSize: toRem(14),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(16),
    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(10),
  },
  listTerm: {
    fontWeight: 600,
  },
});
