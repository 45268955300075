import { createUseStyles } from 'react-jss';
import theme, { toRem } from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
  },
  noOffers: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.neutralGray.medium300,
  },
  btn: {
    display: 'flex',
    height: toRem(24),
    fontSize: toRem(16),
    width: toRem(200),
    padding: '4 8',
    borderRadius: toRem(4),
  },
});
