import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';

const OpenBankingTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loanApplication);
  const obConnected: boolean = data?.business?.obConnected ?? false;

  return (
    <div className={classes.noOpenBanking}>
      {obConnected ? <p>User has connected to Open Banking</p> : <p>User hasn’t connected to Open Banking</p>}
    </div>
  );
};

export default OpenBankingTab;
