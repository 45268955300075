import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    overflow: 'hidden',
    gridColumn: '1 / 4',
    gridRow: '1 / 1',
    borderRadius: toRem(10),
    backgroundColor: theme.colors.background.white,
    padding: toRem(20),
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: toRem(40),
  },
  businessName: {
    fontSize: toRem(18),
    fontFamily: 'Inter',
    color: theme.colors.contrast.black,
    textOverflow: 'ellipsis',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: toRem(40),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(34),
    },
  },
  loanTerms: {
    fontSize: toRem(16),
    fontFamily: 'Inter',
    color: theme.colors.contrast.black,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: toRem(28),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(40),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: toRem(65),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  deferButton: {
    height: toRem(24),
    width: toRem(130),
    fontSize: toRem(10),
    [MEDIA_QUERIES.desktop]: {
      width: toRem(194),
      fontSize: toRem(16),
    },
  },
  chip: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
