import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { businessProfile } from '@/store/client-profile';
import adminVocab from '@/vocabulary';

const BusinessProfileContactInfo: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(businessProfile);
  const fullName = data?.companyName ?? adminVocab.na;
  const phoneNumber = data?.phone ?? adminVocab.na;
  const email = data?.email ?? adminVocab.na;

  return (
    <div className={classes.container}>
      <p>Contact Info: </p>
      <div className={classes.userData}>
        <p>
          <span style={{ fontWeight: 600 }}>Company Name:</span> {fullName}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Mobile Number:</span> {phoneNumber}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Email:</span> {email}
        </p>
      </div>
    </div>
  );
};

export default BusinessProfileContactInfo;
