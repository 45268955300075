import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { IMutationOptions } from '../../interfaces/mutation';
import { IBusiness } from '../../interfaces';
import {
  addBusiness,
  deleteBusiness,
  getBusinesses,
  getBusinessesById,
  getBusinessLocations,
  refreshScreenBusiness,
  screenBusiness,
  updateBusiness,
} from '../requests';

export const businessQueryKeys = {
  businesses: () => ['businesses'],
  business: (id: string | undefined) => ['businesses', id],
  businessLocations: () => ['businesses', 'locations'],
};

export const useBusinessesQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(businessQueryKeys.businesses(), ({ signal }) => getBusinesses({ fetcher, options: { signal } }), {
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};

export const useBusinessLocationsQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(
    businessQueryKeys.businessLocations(),
    ({ signal }) => getBusinessLocations({ fetcher, options: { signal } }),
    {
      initialData: [],
    },
  );
};

export const useBusinessQuery = (id?: string) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    businessQueryKeys.business(id),
    ({ signal }) => getBusinessesById(id, { fetcher, options: { signal } }),
    {
      initialData: null,
      enabled: !!id,
    },
  );
};

export const useAddBusinessMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((business: IBusiness) => addBusiness(business, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateBusinessMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, business }: { id: string; business: Partial<IBusiness> }) => updateBusiness(id, business, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useDeleteBusinessMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deleteBusiness(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useScreenBusinessMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => screenBusiness(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useRefreshScreenBusinessMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => refreshScreenBusiness(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
