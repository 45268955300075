import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    gridColumn: '1 / 5',
    borderRadius: toRem(10),
    backgroundColor: theme.colors.background.white,
    padding: toRem(30),
    overflow: 'hidden',
  },
  tabs: {
    display: 'flex',
    flexWrap: 'wrap !important',
    overflow: 'auto',
  },
  content: {},
});
