import { createUseStyles } from 'react-jss';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  googleButton: ({ disabled, width, height, style }: any) => ({
    ...{
      cursor: disabled ? 'auto' : 'pointer',
      background: '#FFFFFF',
      border: `1.5px solid ${theme.colors.neutralGray.light200}`,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      borderRadius: 4,
      font: 'Roboto, sans-serif',
      width: width < 181 ? 'auto' : width,
      height: height < 43 ? 'auto' : height,
      '& > div': {
        marginRight: 10,
        padding: 10,
        borderRadius: 2,
      },
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.9,
      },
      '&:disabled': {
        opacity: 0.6,
      },
      '&:active': {
        cursor: 'pointer',
        opacity: 1,
      },
    },
    ...style,
  }),
  icon: {
    left: 16,
    position: 'relative',
  },
  buttonContent: {
    padding: [16, 0],
    fontWeight: 500,
    fontSize: 14,
    color: `${theme.colors.text.headline}`,
    lineHeight: 1.5,
    flexGrow: 1,
    textAlign: 'center',
  },
});
