import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: toRem(20),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: toRem(12),
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(14),
    },
  },
  tagCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
