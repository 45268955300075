import { FC } from 'react';
import { LoanBookTable } from '@/components/LoanBookTable';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { DownloadCSV } from '@/components/DownloadCSV';
import { getLoansCSV } from '@nayla/common';

const LoanBook: FC = () => {
  return (
    <Layout title={adminVocab.loans.title} subtitle={adminVocab.loans.tableDescription}>
      <DownloadCSV downloadFn={getLoansCSV} fileName="loans" />
      <LoanBookTable />
    </Layout>
  );
};

export default LoanBook;
