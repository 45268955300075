import { FC, useEffect } from 'react';
import useStyles from '@/components/Login/styles';
import useSignInStyles from '../SignIn/styles';
import adminVocab from '@/vocabulary';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import Text from '@/components/Text';
import Form from '@stryberventures/gaia-react.form';
import * as yup from 'yup';
import Button from '@stryberventures/gaia-react.button';
import InputPassword from '@stryberventures/gaia-react.input-password';
import { passwordRegExp, useForgotPasswordTokenConfirmMutation } from '@nayla/common';

const ForgotPasswordTokenConfirm: FC = () => {
  let token = '';
  const classes = useStyles(),
    signInClasses = useSignInStyles(),
    navigate = useNavigate(),
    onSuccess = () => {
      enqueueSnackbar(adminVocab.authentication.passwordChanged, { variant: 'success' });
      navigate('/');
    },
    onError = () => {
      enqueueSnackbar(adminVocab.errors.oopsSomethingWentWrong, { variant: 'error' });
      navigate('/');
    },
    { mutate: resetPassword, isLoading } = useForgotPasswordTokenConfirmMutation({
      onSuccess,
      onError,
    });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    token = urlParams.get('token') ?? '';
    if (!token) {
      enqueueSnackbar(adminVocab.errors.oopsSomethingWentWrong, { variant: 'error' });
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className={signInClasses.container}>
      <div className={classes.wrapper}>
        <div className={classes.logoWrapper}></div>
        <div className={classes.screen}>
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={classes.title}>
              {adminVocab.authentication.forgotPassword}
            </Text>
            <Form
              className={classes.form}
              onSubmit={(data: any) => resetPassword({ token: token, password: data.password })}
              validationSchema={yup.object({
                password: yup.string().required().matches(passwordRegExp, {
                  message: adminVocab.authentication.validationMessage,
                }),
                repeatPassword: yup
                  .string()
                  .nullable()
                  .oneOf([yup.ref('password'), null], adminVocab.authentication.passwordsDoNotMatch),
              })}
            >
              <InputPassword
                height={56}
                name="password"
                autoComplete="new-password"
                variant="floatingLabel"
                hint={adminVocab.authentication.passwordHint}
                fullWidth
                label={adminVocab.placeholders.password}
                placeholder={adminVocab.users.typePassword}
              />
              <InputPassword
                height={56}
                name="repeatPassword"
                autoComplete="new-password"
                variant="floatingLabel"
                fullWidth
                label={adminVocab.authentication.repeatPassword}
                placeholder={adminVocab.users.typePassword}
              />
              <div className={signInClasses.buttonContainer}>
                <Button className={classes.button} type="submit">
                  {isLoading ? adminVocab.loading : adminVocab.submit}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordTokenConfirm;
