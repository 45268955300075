import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    gap: toRem(50),
  },
  creditScore: {
    display: 'flex',
    gap: toRem(25),
    flexDirection: 'column',
  },
  riskAssessmentState: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  comments: {
    display: 'flex',
    gap: toRem(60),
    flexDirection: 'column',
  },
  submit: {
    padding: `0 !important`,
    fontSize: toRem(12),
    fontWeight: 600,
    [MEDIA_QUERIES.desktop]: {
      fontSize: toRem(16),
      height: toRem(40),
      width: toRem(230),
    },
  },
  tagCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
