import { IFetchRequest, IBusiness, IScreenLoanResponse, IBusinessLocation } from '../../interfaces';
import { common } from '../index';

export const getBusinesses = ({ fetcher, options }: IFetchRequest): Promise<IBusiness[]> => {
  return fetcher.get(`${common.endpoints.path.BUSINESSES_ENDPOINT}`, options);
};

export const getBusinessLocations = ({ fetcher, options }: IFetchRequest): Promise<IBusinessLocation[]> => {
  return fetcher.get(`${common.endpoints.path.BUSINESSES_ENDPOINT}/locations`, options);
};

export const getBusinessesById = (id: string | undefined, { fetcher, options }: IFetchRequest): Promise<IBusiness> => {
  if (!id) throw new Error('id is required');
  return fetcher.get(`${common.endpoints.path.BUSINESSES_ENDPOINT}/${id}`, options);
};

export const addBusiness = (business: IBusiness, { fetcher, options }: IFetchRequest): Promise<IBusiness> => {
  return fetcher.post(common.endpoints.path.BUSINESSES_ENDPOINT, { body: JSON.stringify(business), ...options });
};

export const updateBusiness = (
  id: string,
  business: Partial<IBusiness>,
  { fetcher, options }: IFetchRequest,
): Promise<IBusiness> => {
  return fetcher.patch(`${common.endpoints.path.BUSINESSES_ENDPOINT}/${id}`, {
    body: JSON.stringify(business),
    ...options,
  });
};

export const deleteBusiness = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.BUSINESSES_ENDPOINT}/${id}`, options);
};

export const screenBusiness = (id: string, { fetcher, options }: IFetchRequest): Promise<IScreenLoanResponse> => {
  return fetcher.post(`${common.endpoints.path.BUSINESSES_ENDPOINT}/screen/${id}`, options);
};

export const refreshScreenBusiness = (
  id: string,
  { fetcher, options }: IFetchRequest,
): Promise<IScreenLoanResponse> => {
  return fetcher.patch(`${common.endpoints.path.BUSINESSES_ENDPOINT}/screen/${id}`, options);
};
