import React, { useState } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import adminVocab from '@/vocabulary';
import { useNavigate } from 'react-router-dom';
import UserProfile from '@/components/UserProfile';
import SearchInput from '@stryberventures/gaia-react.search-input';
import {
  DocumentIcon,
  ArrowIcon,
  HomeIcon,
  CalendarIcon,
  FilesIcon,
  ProfileIcon,
  ContentCopyIcon,
  CountIcon,
} from '@stryberventures/gaia-react.icons';
import { useAtom } from 'jotai';
import { sidebarState } from '@nayla/ui';
import SearchModal from '../SearchModal';

const SideBar: React.FC<any> = () => {
  const [sidebarStateValue, setSidebarState] = useAtom(sidebarState);
  const classes = useStyles(sidebarStateValue);
  const [showSearchModal, setShowSearchModal] = useState(false);
  // const [searchedText, setSearchedText] = useState('');

  const navigate = useNavigate();
  const dashboardConfiguration = [
    {
      path: '/home',
      text: adminVocab.menuItems.homePage,
      icon: <HomeIcon />,
    },
    {
      path: '/loan-applications',
      text: adminVocab.menuItems.loanApplications,
      icon: <FilesIcon />,
    },
    {
      path: '/loans',
      text: adminVocab.menuItems.loanBook,
      icon: <DocumentIcon />,
    },
    {
      path: '/instalments',
      text: adminVocab.menuItems.instalments,
      icon: <CalendarIcon />,
    },
    {
      path: '/loan-products',
      text: adminVocab.menuItems.loanProducts,
      icon: <ContentCopyIcon />,
    },
    {
      path: '/calculator',
      text: adminVocab.menuItems.calculator,
      icon: <CountIcon variant="plus" />,
    },
    {
      path: '/user-profiles',
      text: adminVocab.menuItems.userProfiles,
      icon: <ProfileIcon />,
    },
    {
      path: '/business-profiles',
      text: adminVocab.menuItems.businessProfiles,
      icon: <ProfileIcon />,
    },
    {
      path: '/users',
      text: adminVocab.menuItems.admins,
      icon: <ProfileIcon />,
    },
    {
      path: '/settings',
      text: adminVocab.menuItems.settings,
      icon: <ProfileIcon />,
    },
  ];

  return (
    <div className={classNames(classes.sidebar, sidebarStateValue && classes.sidebarOpen)} id={'sidebar'}>
      {sidebarStateValue ? (
        <div
          className={classNames(classes.bigLogoContainer)}
          onClick={() => {
            setSidebarState(false);
          }}
        >
          <img src="/Nayla-full.svg" alt={adminVocab.alt.logo} className={classes.bigLogo} />
          <ArrowIcon variant="left" onClick={() => setSidebarState(false)} />
        </div>
      ) : (
        <div
          className={classNames(classes.smallLogoContainer)}
          onClick={() => {
            setSidebarState(true);
          }}
        >
          <img src="/Nayla-logo.svg" alt={adminVocab.alt.logo} className={classes.logo} />
        </div>
      )}
      <div
        className={classNames(classes.buttonsContainer)}
        id={'button-container'}
        onMouseDownCapture={(e: any) => {
          if (e.target.id) {
            setSidebarState(true);
          }
        }}
      >
        {sidebarStateValue && (
          <div className={classNames(classes.searchInputContainer)}>
            <SearchInput
              fullWidth
              onBlur={() => {}}
              onClick={() => {
                setShowSearchModal(true);
              }}
              onFocus={function noRefCheck() {}}
              placeholder="Search"
              size="medium"
            />
          </div>
        )}
        {dashboardConfiguration
          //SEARCH INSIDE SIDEBAR
          // .filter((e) => e.text.toLowerCase().includes(searchedText.toLowerCase()))
          .map((e, i) => {
            return (
              <div
                className={classNames(classes.iconButton)}
                key={i}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(e.path);
                }}
              >
                <div className={classNames(classes.dashboardIcon)}>{e.icon}</div>
                {sidebarStateValue && <div className={classNames(classes.buttonText)}>{e.text}</div>}
              </div>
            );
          })}
      </div>
      <div className={classNames(classes.profileContainer)}>
        <UserProfile isOpen={sidebarStateValue} />
      </div>
      {showSearchModal && <SearchModal setOpen={setShowSearchModal} />}
    </div>
  );
};

export default SideBar;
