import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: toRem(25),
  },
  btn: {
    width: toRem(280),
    height: toRem(40),
    fontSize: toRem(16),
  },
  confirmAnalysisOne: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  confirmAnalysisTwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    width: '100%',
  },
  divider: {
    height: toRem(1),
    width: '100%',
    backgroundColor: theme.colors.neutralGray.light200,
  },
});
