import useStyles from './styles';
import { EditIcon } from '@stryberventures/gaia-react.icons';
import theme from '@nayla/ui/styles';
import Button from '@stryberventures/gaia-react.button';
import * as React from 'react';
import { formatDate, ILoanProduct, numberWithCommas } from '@nayla/common';
import { NavLink, useNavigate } from 'react-router-dom';
import adminVocab from '@/vocabulary';
import { LoanProductForm } from '@/components/LoanProductForm';

interface ILoanProductCardProps {
  loanProduct: ILoanProduct;
  detailLink?: string;
  editEnabled?: boolean;
}

const LoanProductCard = ({ loanProduct, detailLink, editEnabled }: ILoanProductCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [editModalShown, setEditModalShown] = React.useState(false);
  const loanAmounts =
    `${numberWithCommas(loanProduct?.minLoanAmount?.toString())}-${numberWithCommas(
      loanProduct?.maxLoanAmount?.toString(),
    )}` ?? adminVocab.na;
  const terms = `${loanProduct?.minTerms}-${loanProduct?.maxTerms} months` ?? adminVocab.na;
  const adminFees = loanProduct?.adminFees
    ? `${parseInt(loanProduct?.adminFees?.toString()).toFixed(2)}`
    : adminVocab.na;

  /* Provide edit and details popups */

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{loanProduct?.name}</div>
        {editEnabled && (
          <Button
            className={classes.editButton}
            size="medium"
            onClick={() => setEditModalShown(true)}
            iconRight={<EditIcon fill={theme.colors.primary.main500} width={16} height={16} />}
            variant="ghost"
          >
            {adminVocab.table.actions.edit}
          </Button>
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.contentColumn}>
          <div>Admin Fees %:</div>
          <div className={classes.mainFigure}>{adminFees}</div>
          <div>Loan Product ID: {loanProduct?.externalId}</div>
        </div>
        <div className={classes.contentColumn}>
          <div>Loan Amount Range:</div>
          <div className={classes.mainFigure}>{loanAmounts}</div>
          <div>Date created: {formatDate(new Date().toString())}</div>
        </div>
        <div className={classes.contentColumn}>
          <div>Term Range:</div>
          <div className={classes.mainFigure}>{terms}</div>
          <span>&nbsp;</span>
        </div>
        <div className={classes.contentColumn}>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          {detailLink && (
            <NavLink to="#" onClick={() => navigate(detailLink)}>
              See more
            </NavLink>
          )}
        </div>
      </div>
      {editModalShown && <LoanProductForm id={loanProduct.id} open={editModalShown} setOpen={setEditModalShown} />}
    </div>
  );
};

export default LoanProductCard;
