import { FC } from 'react';
import useStyles from './styles';
import Login from '@/components/Login';

const SignIn: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Login />
    </div>
  );
};

export default SignIn;
