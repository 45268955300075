import { FC, useState } from 'react';
import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import Tabs from '@stryberventures/gaia-react.tabs';
import DetailsTab from './details';

const TAB_IDS = {
  DETAILS: 'details',
};
const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.DETAILS,
    label: adminVocab.loanProducts.tabs.details,
  },
];

const LoanProductTabs: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TAB_IDS.DETAILS);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>{activeTab === TAB_IDS.DETAILS && <DetailsTab />}</div>
    </div>
  );
};

export default LoanProductTabs;
