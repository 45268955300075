export interface ICardPayment {
  checkoutId?: string;
  businessExternalId: string;
}

export interface ICardPaymentRegistration {
  checkoutId?: string;
  businessId: string;
}

export enum DisbursementState {
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export interface IDisburseRequest {
  offerId: string;
}

export interface ICreatePaymentAccountRequest {
  loanApplicationId: string;
}
