import { FC, useEffect } from 'react';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { useLoanQuery } from '@nayla/common';
import LoanOverview from '@/screens/Loans/detail/overview';
import LoanContactInfo from '@/screens/Loans/detail/contact';
import LoanTabs from '@/screens/Loans/detail/tabs';
import { useSetAtom } from 'jotai';
import { loan } from '@/store/loan';

const LoanDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data } = useLoanQuery(id, ['user', 'loanApplication']);
  const setLoan = useSetAtom(loan);

  useEffect(() => {
    setLoan(data ?? null);
  }, [data, setLoan]);

  return (
    <Layout title={adminVocab.loans.title} subtitle={`Active loans / ${data?.loan.externalId}`}>
      {data && (
        <div className={classes.grid}>
          <LoanOverview />
          <LoanContactInfo />
          <LoanTabs />
        </div>
      )}
    </Layout>
  );
};

export default LoanDetail;
