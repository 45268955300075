import { FC } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { formatDate } from '@nayla/common';
import { businessProfile } from '@/store/client-profile';
import adminVocab from '@/vocabulary';

const BusinessInformationTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(businessProfile);
  const businessName = data?.companyName ?? adminVocab.na;

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <p>
          {adminVocab.businesses.properties.name}: {businessName}
        </p>
        <p>
          {adminVocab.businessProfiles.properties.CR}: {data?.commercialRegistrationNumber}
        </p>
        <p>
          {adminVocab.businesses.properties.entityNumber}: {data?.crEntityNumber ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.issueDate}:{' '}
          {data?.kybData?.issueDate ? formatDate(data?.kybData?.issueDate) : adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.expiryDate}:{' '}
          {data?.kybData?.expiryDate ? formatDate(data?.kybData?.expiryDate) : adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.businessType}: {data?.businessType?.name ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.status}: {data?.status?.name ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.website}: {data?.website ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.zipCode}: {data?.kybData?.data?.address?.general.zipCode ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.fax}: {data?.kybData?.data?.address?.general.fax1 ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.telephone1}:{' '}
          {data?.kybData?.data?.address?.general.telephone1 ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.telephone2}:{' '}
          {data?.kybData?.data?.address?.general.telephone2 ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.location}: {data?.location?.name ?? adminVocab.na}
        </p>
        <p>
          {adminVocab.businesses.properties.paidAmount}: {adminVocab.na}
        </p>
      </div>
    </div>
  );
};

export default BusinessInformationTab;
