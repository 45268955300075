import useStyles from './styles';
import {
  formatDate,
  getTagColorByLoanApplicationState,
  IRiskAssessment,
  LoanApplicationState,
  numberWithCommas,
  RiskCategoryEnum,
} from '@nayla/common';
import adminVocab from '@/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import vocab from '@nayla/vocabulary';
import { CREDIT_SCORE_OPTIONS } from '@nayla/ui/components/RiskAssessmentDropdown';

interface RiskAnalysisViewProps {
  riskAssessment: IRiskAssessment;
  preliminaryAssessmentResult: LoanApplicationState | 'NA';
}

const RiskAnalysisView = ({ riskAssessment, preliminaryAssessmentResult }: RiskAnalysisViewProps) => {
  const classes = useStyles();

  const createdDate = riskAssessment?.createdAt ? formatDate(riskAssessment?.createdAt) : null;
  const creditOfficerName =
    riskAssessment?.creditRiskAnalyst?.firstName +
    ' ' +
    riskAssessment?.creditRiskAnalyst?.lastName +
    ' (Credit Risk Officer)';

  return (
    <div className={classes.container}>
      <div className={classes.creditScore}>
        <div className={classes.riskAssessmentState}>
          <p>{adminVocab.loanApplication.riskAssessment.assessmentOutcome}</p>
          <Tag
            color={
              preliminaryAssessmentResult === 'NA'
                ? 'neutralGray'
                : getTagColorByLoanApplicationState(preliminaryAssessmentResult)
            }
            className={classes.tagCentered}
          >
            {vocab().loanState[preliminaryAssessmentResult]}
          </Tag>
        </div>
        <div>
          <p>{adminVocab.loanApplication.riskAssessment.properties.creditScore}:</p>
          <p className={classes.mainFigure}>{riskAssessment.creditScore}</p>
        </div>
        <div>
          <p>{adminVocab.loanApplication.riskAssessment.properties.riskCategory}:</p>
          {riskAssessment.riskCategory ? (
            <Tag
              className={classes.tagCentered}
              color={CREDIT_SCORE_OPTIONS.find((score) => score.value === riskAssessment.riskCategory.name)?.color}
            >
              {vocab().loanProducts.riskCategories[riskAssessment.riskCategory?.name as RiskCategoryEnum]}
            </Tag>
          ) : riskAssessment.rejected ? (
            <Tag className={classes.tagCentered} color="error">
              {vocab().loanProducts.riskCategories[RiskCategoryEnum.REJECTED]}
            </Tag>
          ) : (
            adminVocab.na
          )}
        </div>
        <div>
          <p>{adminVocab.loanApplication.riskAssessment.properties.maxLoanAmount}:</p>
          <p className={classes.mainFigure}>{numberWithCommas(riskAssessment.maximumAmount)}</p>
        </div>
      </div>
      <div className={classes.comments}>
        <div className={classes.commentsSpace}>
          <p style={{ fontWeight: 600 }}>
            {createdDate} - {creditOfficerName}
          </p>
          {riskAssessment.comment}
        </div>
      </div>
    </div>
  );
};

export default RiskAnalysisView;
