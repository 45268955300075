import adminVocab from '@/vocabulary';
import { useNavigate, useRouteError } from 'react-router-dom';
import useStyles from './styles';
import TextLink from '@stryberventures/gaia-react.text-link';

export default function ErrorPage() {
  const classes = useStyles(),
    navigate = useNavigate(),
    error = useRouteError() as any;

  return (
    <div className={classes.container}>
      <h1>{adminVocab.errors.oops}</h1>
      <p>{adminVocab.errors.unexpected}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <TextLink href="" onClick={() => navigate('/')}>
        {adminVocab.goMain}
      </TextLink>
    </div>
  );
}
