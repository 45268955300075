import { FC, useState } from 'react';
import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import Tabs from '@stryberventures/gaia-react.tabs';
import SubmissionTab from '@/screens/LoanApplications/detail/tabs/submission';
import RiskAssessmentTab from '@/screens/LoanApplications/detail/tabs/risk-assessment';
import HistoryTab from '@/screens/LoanApplications/detail/tabs/history';
import ContractNegotiationTab from '@/screens/LoanApplications/detail/tabs/contract-negotiation';
import DisbursementTab from '@/screens/LoanApplications/detail/tabs/disbursement';

const TAB_IDS = {
  SUBMISSION: 'submission',
  RISK_ASSESSMENT: 'riskAssessment',
  CONTRACT_NEGOTIATION: 'contractNegotiation',
  DISBURSEMENT: 'disbursement',
  LOGS: 'logs',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.SUBMISSION,
    label: adminVocab.loanApplication.tabs.submission,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.RISK_ASSESSMENT,
    label: adminVocab.loanApplication.tabs.riskAssessment,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CONTRACT_NEGOTIATION,
    label: adminVocab.loanApplication.tabs.contractNegotiation,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.DISBURSEMENT,
    label: adminVocab.loanApplication.tabs.disbursement,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.LOGS,
    label: adminVocab.loanApplication.tabs.logs,
  },
];

const LoanApplicationTabs: FC = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TAB_IDS.SUBMISSION);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>
        {activeTab === TAB_IDS.SUBMISSION && <SubmissionTab />}
        {activeTab === TAB_IDS.RISK_ASSESSMENT && <RiskAssessmentTab />}
        {activeTab === TAB_IDS.LOGS && <HistoryTab />}
        {activeTab === TAB_IDS.CONTRACT_NEGOTIATION && <ContractNegotiationTab />}
        {activeTab === TAB_IDS.DISBURSEMENT && <DisbursementTab />}
      </div>
    </div>
  );
};

export default LoanApplicationTabs;
