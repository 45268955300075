import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '2rem',
  },
  amlInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
  },
  amlRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: toRem(30),
    alignItems: 'center',
  },
  btnContainer: {
    width: toRem(192),
    height: toRem(40),
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(15),
    alignItems: 'flex-start',
  },
  btn: {
    width: toRem(192),
    height: toRem(40),
  },
  checkboxContainer: {
    marginTop: toRem(15),
    display: 'flex',
    alignItems: 'center',
  },
  refreshContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checkbox: {
    marginTop: '5px',
  },
});
