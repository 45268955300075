import Icon from '@/components/ButtonGoogleLogin/icon';
import useStyles from './styles';
import vocabulary from '@/vocabulary';

interface IButtonGoogleLogin {
  onClick: () => void;
  children?: any;
  themeType?: 'dark' | 'light';
  disabled?: boolean;
  label?: string;
  width?: number;
  height?: number;
  style?: any;
}

const ButtonGoogleLogin = (props: IButtonGoogleLogin) => {
  const {
    width = '100%',
    height = 'auto',
    children,
    themeType = 'light',
    label = vocabulary.authentication.googleSignIn,
    onClick,
    disabled,
    style,
  } = props;

  const classes = useStyles({
    disabled,
    themeType,
    width,
    height,
    style,
  });

  return (
    <button className={classes.googleButton} type="button" disabled={disabled} onClick={() => onClick && onClick()}>
      {Icon && <Icon className={classes.icon} />}
      <span className={classes.buttonContent}>{children || label}</span>
    </button>
  );
};

export default ButtonGoogleLogin;
