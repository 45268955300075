import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLoanApplicationQuery } from '@nayla/common';
import LoanApplicationOverview from '@/screens/LoanApplications/detail/overview';
import adminVocab from '@/vocabulary';
import LoanApplicationContactInfo from '@/screens/LoanApplications/detail/contact';
import useStyles from './styles';
import Layout from '@nayla/ui/components/Layout';
import { useSetAtom } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import LoanApplicationTabs from '@/screens/LoanApplications/detail/tabs';

export const loanApplicationDetailViewExpandArray = [
  'loanProduct',
  'business',
  'user',
  'businessSnapshot',
  'userSnapshot',
  'notes',
  'documents',
];

const LoanApplicationEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data } = useLoanApplicationQuery(id, loanApplicationDetailViewExpandArray);
  const setLoanApplication = useSetAtom(loanApplication);

  useEffect(() => {
    setLoanApplication(data);
  }, [data, setLoanApplication]);

  return (
    <Layout title={adminVocab.loanApplication.detailTitle}>
      {data && (
        <div className={classes.grid}>
          <LoanApplicationOverview />
          <LoanApplicationContactInfo />
          <LoanApplicationTabs />
        </div>
      )}
    </Layout>
  );
};

export default LoanApplicationEdit;
